import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isFunction from 'lodash/isFunction';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import browser from 'chameleon/ui-stack/utilities/browser-detection';
import log from 'chameleon/ui-stack/utilities/log';

class SplitIOHandler {
  constructor(options) {
    this.options = options || {};
  }

  _singleFlagApiCall() {
    return get(this.options, 'singleFlagSplitFunction', 'getTreatment');
  }

  _multiFlagApiCall() {
    return get(this.options, 'multipleFlagSplitFunction', 'getTreatments');
  }

  /**
   * @member {function}
   * @desc Validates provided configuration and checks for window.splitio.
   * @return {boolean}
   */
  _validateSetup() {
    const { key, splitName, callback } = this.options;

    return !!(
      (isString(key) || isNumber(key)) &&
      (isString(splitName) || isArray(splitName)) &&
      isFunction(callback) &&
      typeof window.splitio !== 'undefined'
    );
  }
  /**
   * @member {function}
   * @desc Provides string or object callback argument for failed implementations.
   * @return {object|string}
   */
  _provideErrorArgs() {
    const { splitName } = this.options;
    let args = 'FAIL';
    /**
     * If we have multiple split names, the body of the callback is expecting to evaluate
     * an object with split names as keys.
     */
    if (isArray(splitName)) {
      args = {};
      splitName.forEach((t) => (args[t] = 'FAIL'));
    }

    return args;
  }
  /**
   * @member {function}
   * @desc Checks SplitIOHandler#_validateSetup for required configuration.
   *       If config is valid, invoke splitio. Otherwise, capture error and invoke callback.
   */
  invoke() {
    const {
      key,
      attributes = {},
      splitName,
      callback,
      listenCallback,
      authorizationKey = SPLITIO_API_KEY,
    } = this.options;
    /**
     * If SplitIOHandler#_validateSetup returns true, proceed
     * with setup and setting callback to the SDK_READY event.
     */
    if (this._validateSetup()) {
      const splitioInstance = window.splitio({
        core: {
          authorizationKey,
          key,
        },
        sync: {
          /**
           * Because we don't dynamically change the UI during the user
           * session, we don't need to keep checking for changes
           */
          enabled: false,
        },
      });
      const client = splitioInstance.client();
      /**
       * SDK READY handler.
       */
      client.on(client.Event.SDK_READY, () => {
        const valuesToCheck = [
          'isOSX',
          'isIPad',
          'isFirefox',
          'isInternetExplorer',
          'isIE',
          'isEdge',
          'isChrome',
          'isOpera',
          'isIPhone',
          'isIOS',
        ];

        // injecting split attributes to detect based on user agent
        const splitAttributes = {
          ...Object.keys(browser).reduce(
            (browserTypes, functionName) =>
              valuesToCheck.includes(functionName)
                ? {
                    ...browserTypes,
                    [functionName]: browser[functionName](),
                  }
                : browserTypes,
            {},
          ),
          userAgent: browser.getUserAgent(),
          ...attributes,
        };

        /**
         * If multiple split names were provided, invoke client.getTreatmentsWithConfig() or client.getTreatments().
         * If one split name was provided, invoke client.getTreatmentWithConfig() or client.getTreatment().
         *
         * Configs are important for flags that have UI configurations as part of their setup. Instead of getting
         * back the on/off state of the flag we get back an object like { treatment: on|off, config: {..} }
         */
        log('log', 'SplitIO SDK ready');
        callback(
          client[
            isArray(splitName)
              ? this._multiFlagApiCall()
              : this._singleFlagApiCall()
          ](splitName, splitAttributes),
        );
      });
      if (listenCallback) {
        client.on(client.Event.SDK_UPDATE, () => {
          listenCallback(
            client[
              isArray(splitName)
                ? this._multiFlagApiCall()
                : this._singleFlagApiCall()
            ](splitName),
          );
        });
      }
      /**
       * SDK TIMEOUT handler. If this occurs, invoke the callback anyways.
       */
      client.on(client.Event.SDK_READY_TIMED_OUT, () => {
        log('log', 'SplitIO SDK ready timeout');
        callback(this._provideErrorArgs());
      });
    } else {
      /**
       * If a callback was provided but there is some error condition, still invoke the callback.
       */
      if (isFunction(callback)) {
        callback(this._provideErrorArgs());
      }
      /**
       * Capture the error.
       */
      log(
        'warn',
        `Invalid split.io configuration: ${JSON.stringify(this.options)}`,
      );
    }
  }
}

export default SplitIOHandler;

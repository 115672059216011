import { FloatingFocusManager } from '@floating-ui/react';
import ButtonWithIcon from 'chameleon/ui-stack/ui/buttons/button-with-icon';
import React, { Fragment, useEffect, useRef } from 'react';

const Content = (props) =>
  // When the popover is rendered in a portal
  // The Content should be wrapped in the same classes as the container
  props.withPortal ? (
    <div className={props.className}>{props.children}</div>
  ) : (
    // Otherwise just show the content as it is
    <Fragment>{props.children}</Fragment>
  );

const PopoverContentElement = ({
  context,
  initialFocus,
  withPortal,
  classNames,
  floating,
  contentOffset,
  floatingProps,
  maxHeight,
  x,
  y,
  hasClose,
  handleClose,
  children,
  arrowPosition,
  hideArrow,
  closeOnBackwardsFocus = false,
  onMount = () => {},
  focusOrder,
  popoverTitle,
}) => {
  const contentElement = useRef(null);

  useEffect(() => {
    onMount(contentElement);
  }, []);

  const onBlurCapture = (e) => {
    // If we are moving with focus backwards (shift + tab) from this element,
    // then we need to close the popup
    const { target, currentTarget, relatedTarget } = e;
    if (target === currentTarget) {
      if (target.parentElement.previousSibling === relatedTarget) {
        handleClose();
      }
    }
  };

  const paddingStyleContent = popoverTitle ? { padding: '0' } : {};

  return (
    <FloatingFocusManager
      context={context}
      order={focusOrder}
      modal={!closeOnBackwardsFocus}
      returnFocus
      initialFocus={initialFocus}
    >
      <Content withPortal={withPortal} className={classNames.popoverPortal}>
        <div
          onBlurCapture={closeOnBackwardsFocus ? onBlurCapture : null}
          ref={floating}
          className={classNames.popoverContent}
          style={{
            transform: `translate(${Math.round(x, 2)}px,${Math.round(y, 2)}px)`,
            margin: contentOffset,
            maxHeight,
            ...paddingStyleContent,
          }}
          {...floatingProps}
        >
          <div role="presentation" ref={contentElement}>
            {popoverTitle && (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  height: '43px',
                  borderBottom: '1px solid #efefef',
                }}
              >
                <div className="performanceTiersModalHeader">
                  <h4 style={{ marginTop: '30px', marginLeft: '20px' }}>
                    {popoverTitle}
                  </h4>
                </div>
                <div
                  style={{
                    borderLeft: '1px solid #efefef',
                    height: '42px',
                    width: '45px',
                    display: 'flex',
                  }}
                >
                  <ButtonWithIcon
                    isIcon
                    isNaked
                    icon="close"
                    className={classNames.closeButton}
                    aria-label="Close Popup"
                    onClick={handleClose}
                    style={{ position: 'unset', margin: 'auto' }}
                  />
                </div>
              </div>
            )}
            {hasClose && !popoverTitle && (
              <ButtonWithIcon
                isIcon
                isNaked
                icon="close"
                className={classNames.closeButton}
                aria-label="Close Popup"
                onClick={handleClose}
              />
            )}
            {children}
          </div>
        </div>
        {!hideArrow && (
          <div
            className={classNames.arrow}
            style={{
              // prettier-ignore
              transform: `translate(
                ${Math.round(arrowPosition.x, 2)}px,
                ${Math.round(arrowPosition.y, 2)}px
              )`,
            }}
          />
        )}
      </Content>
    </FloatingFocusManager>
  );
};

export default PopoverContentElement;

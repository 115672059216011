module.exports = {
  xm: {
    max: '479px',
  },
  sm: {
    min: '480px',
    max: '736px',
  },
  md: {
    min: '737px',
    max: '993px',
  },
  lg: {
    min: '994px',
    max: '1279px',
  },
  xl: {
    min: '1280px',
  },
  mobile: {
    max: '993px',
  },
  desktop: {
    min: '994px',
  },
  'bb-mobile': {
    max: '767px',
  },
  'bb-desktop': {
    min: '768px',
  },
};

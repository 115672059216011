import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import Icon from '../Icon';

import './index.scss';

const AlertBox = (props) => {
  const {
    children,
    className,
    color,
    header,
    title,
    description,
    onDismiss,
    dismissible,
    icon,
  } = props;

  return (
    <div
      className={cn(
        'alert_box',
        `alert_box--${color}`,
        { 'alert_box--dismissible': dismissible },
        className,
      )}
    >
      {children || (
        <div>
          <Icon className="alert_box__icon" name={icon} size="2.6rem" />
          <h3 className={`text_${color}`}>{`${header}`}</h3>
          <p className={`text_${color}`}>
            {title ? <strong>{title}</strong> : ''}
            <span>{description}</span>
          </p>
        </div>
      )}
      {dismissible && (
        <button className="alert_box__dismiss" onClick={onDismiss}>
          <Icon
            className="alert_box__dismiss-icon"
            name="xmark"
            size="1.3rem"
          />
        </button>
      )}
    </div>
  );
};

AlertBox.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string,
  header: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onDismiss: PropTypes.func,
  dismissible: PropTypes.bool,
  icon: PropTypes.string,
};

AlertBox.displayName = 'AlertBox';

export default AlertBox;

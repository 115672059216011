import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

const Tabs = (props) => {
  const size = props.size || 'default';
  const style = props.style || 'tabs';
  const dir = props.direction || 'horz';
  const classes = cn([
    'tabs',
    `tabs--size_${size}`,
    `tabs--style_${style}`,
    `tabs--direction_${dir}`,
    props.className,
  ]);

  return <div className={classes}>{props.children}</div>;
};

Tabs.propTypes = {
  size: PropTypes.oneOf(['default', 'large']),
  style: PropTypes.oneOf(['tabs', 'bar']),
  direction: PropTypes.oneOf(['horz', 'vert']),
};

export default Tabs;

import {
  RESTRICTIONS_BY_USER_TYPE_AND_FF,
  RESTRICTIONS_BY_SUBJECT,
  ALL_SUBJECTS_LABEL,
} from 'chameleon-src/constants/subjects';

export const isResourceVisibilityOverriddenByFF = (
  subjectId,
  resource,
  isStudent,
  featureFlag = {},
) => {
  const userType = isStudent ? 'student' : 'teacher';
  const [featureFlagKey, featureFlagValue] =
    Object.entries(featureFlag)?.[0] || [];
  const ff = featureFlagValue ? featureFlagKey : null;

  const subjectsByResByFfByUserType =
    RESTRICTIONS_BY_USER_TYPE_AND_FF?.[resource]?.[ff]?.[userType];

  const configExists = Array.isArray(subjectsByResByFfByUserType);
  const isSubjectRestricted = (subjectsByResByFfByUserType || []).includes(
    +subjectId,
  );

  return configExists && !isSubjectRestricted;
};

export const isSubjectHasResource = (subjectId, resource) =>
  !(
    RESTRICTIONS_BY_SUBJECT[resource] &&
    Array.isArray(RESTRICTIONS_BY_SUBJECT[resource]) &&
    (RESTRICTIONS_BY_SUBJECT[resource].includes(+subjectId) ||
      RESTRICTIONS_BY_SUBJECT[resource].includes(ALL_SUBJECTS_LABEL))
  );

const plugin = require('tailwindcss/plugin');
const flattenColorPalette =
  require('tailwindcss/lib/util/flattenColorPalette').default;
const withAlphaVariable =
  require('tailwindcss/lib/util/withAlphaVariable').default;

module.exports = plugin(({ matchUtilities, theme, variants, corePlugins }) => {
  matchUtilities(
    {
      'border-t': (value) => {
        if (!corePlugins('borderOpacity')) {
          return {
            'border-top-color': value,
          };
        }

        return withAlphaVariable({
          color: value,
          property: 'border-top-color',
          variable: '--tw-border-opacity',
        });
      },
      'border-r': (value) => {
        if (!corePlugins('borderOpacity')) {
          return {
            'border-right-color': value,
          };
        }

        return withAlphaVariable({
          color: value,
          property: 'border-right-color',
          variable: '--tw-border-opacity',
        });
      },
      'border-b': (value) => {
        if (!corePlugins('borderOpacity')) {
          return {
            'border-bottom-color': value,
          };
        }

        return withAlphaVariable({
          color: value,
          property: 'border-bottom-color',
          variable: '--tw-border-opacity',
        });
      },
      'border-l': (value) => {
        if (!corePlugins('borderOpacity')) {
          return {
            'border-left-color': value,
          };
        }

        return withAlphaVariable({
          color: value,
          property: 'border-left-color',
          variable: '--tw-border-opacity',
        });
      },
    },
    {
      values: (({ DEFAULT: _, ...colors }) => colors)(
        flattenColorPalette(theme('borderColor')),
      ),
      variants: variants('borderColor'),
      type: 'color',
    },
  );
});

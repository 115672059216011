import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../../index';
import PopOver from 'chameleon/ui-stack/ui/base/popover';
import Button from 'chameleon/ui-stack/ui/base/button';

import './index.scss';

const AcademicYearSelector = (props) => {
  const {
    chosenEducationPeriodId,
    displayValue: academicYearLabel,
    periods = [],
    onPeriodSelect,
  } = props;

  const didSelectPeriod = (e, id) => {
    onPeriodSelect(id);
  };

  return (
    <div className="ay_wrapper">
      <PopOver
        className="ay_selector"
        size="narrow"
        position="end"
        accessibility={{
          'aria-label': `Selected Option: ${academicYearLabel}`,
          'aria-haspopup': 'listbox',
        }}
        trigger={
          <span className="subsel__label">
            {academicYearLabel}
            {periods.length > 1 && <Icon name="menu_down" size="1.15rem" />}
          </span>
        }
      >
        {periods.map((p, index) => {
          const displayValue = p.displayValue;
          const id = p.id;
          if (id === chosenEducationPeriodId) {
            return null;
          }

          return (
            <Button
              isOutline
              accessibility={{
                ariaLabel: { displayValue },
              }}
              key={`${id}-${index}`}
              onClick={(e) => didSelectPeriod(e, id)}
            >
              {displayValue}
            </Button>
          );
        })}
      </PopOver>
    </div>
  );
};

AcademicYearSelector.displayName = 'AcademicYearSelector';

AcademicYearSelector.propTypes = {
  redirectPath: PropTypes.string,
  fymFrontEndUrl: PropTypes.string,
  fymSubjects: PropTypes.array,
  name: PropTypes.string,
  subjects: PropTypes.array,
};

export default AcademicYearSelector;

import React, { Fragment, useCallback, useState } from 'react';
import './index.scss';
import cn from 'classnames';
import keycode from 'keycode';

const codes = keycode.codes;

const onOpen = (onOpenFunc, setMenuVisible) => {
  onOpenFunc();
  setMenuVisible(true);
};

const onClose = (onCloseFunc, setMenuVisible) => {
  onCloseFunc();
  setMenuVisible(false);
};

const onClick = (
  event,
  onOpenFunc,
  onCloseFunc,
  setMenuVisible,
  isMenuVisible,
) => {
  event.stopPropagation();
  if (isMenuVisible) {
    onClose(onCloseFunc, setMenuVisible);
  } else {
    onOpen(onOpenFunc, setMenuVisible);
  }
};

const keyDownIsActivation = (event) => {
  const evCode = event.keyCode;

  if (evCode === codes.enter || evCode === codes.space) {
    event.stopPropagation();
    event.preventDefault();
    return true;
  }

  return false;
};

const keyDownIsEscape = (event) => {
  const evCode = event.keyCode;

  if (evCode === codes.esc) {
    event.stopPropagation();
    event.preventDefault();
    return true;
  }

  return false;
};

const handleOnKeyDown = (
  event,
  onOpenFunc,
  onCloseFunc,
  setMenuVisible,
  isMenuVisible,
) => {
  if (keyDownIsActivation(event)) {
    onClick(event, onOpenFunc, onCloseFunc, setMenuVisible, isMenuVisible);
  }
};

const handleOpenKeyDown = (event, onCloseFunc, setMenuVisible) => {
  if (keyDownIsEscape(event)) {
    onClose(onCloseFunc, setMenuVisible);
  }
};

const clientRect = () => {
  const [rect, setRect] = useState({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: 0,
    height: 0,
  });
  const ref = useCallback((node) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref];
};

/**
 * This is a reusable context menu component. It's designed to be attached as a button to a specific element,
 * then on click (or keyboard navigation, space/enter), it will display an interactive, pre-style context menu.
 *
 * Unlike the popover component, this is designed to be interactive, and provides several hooks for the consuming
 * element to define any behavior as needed.
 *
 * Elements in the list are intended to be passed in as dictionaries, with an optional `icon` key that is intended
 * to be an instance of ft-cb/Icon, a `label` which is a string representing the item in the list and a `onClick`
 * function, which takes zero parameters and should be void.
 * @param menuOptions - Array[Object] An array of objects with the aforementioned shape
 * @param targetElement - The element that the user will click on to display the context menu
 * @param onOpenFunc - A function which will be run when the context menu is opened. No parameters/void.
 * @param onCloseFunc - A function which will run when the context menu is closed. No parameters/void.
 * @returns {JSX.Element}
 * @constructor
 */
const ContextMenu = ({
  menuOptions,
  targetElement,
  onOpenFunc = () => {},
  onCloseFunc = () => {},
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [rect, ref] = clientRect();

  const { left } = rect;
  const right = window.innerWidth - rect.right;
  const above = rect.top - 75;
  const below = window.innerHeight - rect.bottom - 25;

  const directionalProperties = {
    hDir: right > left ? 'right' : 'left',
    vDir: below > above ? 'down' : 'up',
    height: Math.max(below, above),
    width: Math.max(left, right) + rect.width,
  };

  const contextMenuClasses = cn(
    'context-menu-content',
    `context-menu-${directionalProperties.hDir}`,
    `context-menu-${directionalProperties.vDir}`,
  );

  return (
    <Fragment>
      <span
        ref={ref}
        className="context-menu-landing"
        onClick={(event) =>
          onClick(event, onOpenFunc, onCloseFunc, setMenuVisible, menuVisible)
        }
        role="button"
        tabIndex="0"
        onKeyDown={(event) =>
          handleOnKeyDown(
            event,
            onOpenFunc,
            onCloseFunc,
            setMenuVisible,
            menuVisible,
          )
        }
      >
        {targetElement}
      </span>
      {menuVisible && (
        <Fragment>
          <span
            className="context-menu-outside"
            role="button"
            tabIndex="0"
            onClick={(e) => {
              e.stopPropagation();
              onClose(onCloseFunc, setMenuVisible);
            }}
          />
          <div
            role="button"
            tabIndex="0"
            className="context-menu-container"
            onKeyDown={(event) =>
              handleOpenKeyDown(event, onCloseFunc, setMenuVisible)
            }
          >
            <span className={contextMenuClasses}>
              <ul className="context-menu-content-list">
                {menuOptions.map((menuItem) => (
                  <li
                    key={menuItem.label}
                    className="context-menu-content-item"
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        menuItem.onClick();
                        setMenuVisible(false);
                      }}
                      onKeyDown={(e) => {
                        if (keyDownIsActivation(e)) {
                          e.stopPropagation();
                          menuItem.onClick();
                          setMenuVisible(false);
                        }
                      }}
                      tabIndex="0"
                      role="button"
                    >
                      {menuItem.icon && (
                        <span className="context-menu-content-item-icon">
                          {menuItem.icon}
                        </span>
                      )}
                      <span className="context-menu-content-item-label">
                        {menuItem.label}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </span>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ContextMenu;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import CompatibilityCheck from './index';
import clearLocalStorage from '../clearLocalStorage';
import { Icon } from '../../index';
import Button from 'chameleon/ui-stack/ui/base/button';
import './index.scss';

/**
 * @desc Simple debugging view that displays compatibility data and debug tools.
 * @param {Array.<string>} domainsToCheck Optional list of endpoint domains to check for accessibility.
 * @param {function} onCheckFail Optional failure callback, is passed current state.
 * @param {function} onCheckPass Optional success callback, is passed current state.
 */
class CompatibilityView extends PureComponent {
  static displayName = 'CompatibilityView';

  static propTypes = {
    domainsToCheck: PropTypes.arrayOf(PropTypes.string),
    onCheckFail: PropTypes.func,
    onCheckPass: PropTypes.func,
  };

  static defaultProps = {
    domainsToCheck: [],
    onCheckFail: noop,
    onCheckPass: noop,
  };

  constructor(props) {
    super(props);

    this.state = {
      compatible: true,
      debugData: [
        '--- BEGIN DEBUG DATA ---',
        `LOCAL DATE AND TIME: ${new Date()}`,
        `UA STRING: ${window.navigator.userAgent}`,
      ],
      support: {},
      storageSupport: true,
      endpointsSupport: null,
    };

    this.onClearStorage = this.onClearStorage.bind(this);
    this.onCheckDomains = this.onCheckDomains.bind(this);
    this.renderMethodSupportTable = this.renderMethodSupportTable.bind(this);
    this.renderDebugOutput = this.renderDebugOutput.bind(this);
  }

  onClearStorage() {
    if (
      window.confirm(
        'This will clear storage data for this domain. Please confirm.',
      )
    ) {
      clearLocalStorage([]);
    }
  }

  onCheckDomains() {
    const { domainsToCheck } = this.props;

    CompatibilityCheck.checkDomains(domainsToCheck)
      .then(() => {
        this.setState({
          endpointsSupport: true,
        });
      })
      .catch((errorObj) => {
        this.setState((prevState) => ({
          endpointsSupport: false,
          compatible: false,
          debugData: [
            ...prevState.debugData,
            `API ACCESSIBILITY ERROR: ${errorObj.error}`,
          ],
        }));
      });
  }

  renderMethodSupportTable() {
    const { support, storageSupport } = this.state;

    return (
      <table className="supported_methods_table">
        <tbody>
          <tr>
            <td colSpan={2} className="methods_table_header">
              Required Browser Feature Support
            </td>
          </tr>
          {Object.keys(support).map((method, idx) => (
            <tr key={idx}>
              <td key={`name-${idx}`}>{method}</td>
              <td
                key={`supported-${idx}`}
                className={`${support[method] ? '' : 'no_'}support`}
              >
                {support[method] ? 'Supported' : 'Not Supported'}
              </td>
            </tr>
          ))}
          <tr>
            <td>localStorage</td>
            <td className={`${storageSupport ? '' : 'no_'}support`}>
              {storageSupport ? 'Supported' : 'Not Supported'}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderDebugOutput() {
    const { debugData } = this.state;
    const value = [...debugData, '--- END DEBUG DATA ---'].join(' \r\n');

    return <textarea readOnly rows={debugData.length + 1} value={value} />;
  }

  componentDidUpdate() {
    const { onCheckPass, onCheckFail } = this.props;

    if (this.state.compatible) {
      onCheckPass(this.state);
    } else {
      onCheckFail(this.state);
    }
  }

  componentDidMount() {
    const { checkBrowser, checkBrowserStorage } = CompatibilityCheck;

    /**
     * Check for required browser methods.
     */
    Promise.all([checkBrowser(), checkBrowserStorage(true)])
      .then((results) => {
        const methods = results[0];
        const storage = results[1];
        const errorPrefix = 'BROWSER FEATURE ERROR: ';

        this.setState((prevState) => ({
          compatible: methods.compatible && storage.compatible,
          debugData:
            methods.error || storage.error
              ? [
                  ...prevState.debugData,
                  errorPrefix + methods.error,
                  errorPrefix + storage.error,
                ]
              : prevState.debugData,
          support: methods.support,
          storageSupport: !storage.error,
        }));
      })
      .catch((error) => {
        /**
         * @todo Capture this exception and handle in UI.
         */
        /* eslint-disable */
        console.error(error);
        /* eslint-enable */
      });
  }

  render() {
    const { compatible, support, endpointsSupport, storageSupport } =
      this.state;

    return (
      <section className="CompatibilityView_container">
        <h2>Browser Compatibility and Debug Tools</h2>
        <h2 className={`supported_message ${compatible ? '' : 'in'}compatible`}>
          {`Your browser is ${
            compatible ? '' : 'in'
          }compatible with this site.`}
          <Icon
            name={`${compatible ? 'circle_check' : 'circle_with_x'}`}
            size="3rem"
            className={`icon--${compatible ? 'green' : 'red'}`}
          />
        </h2>
        {!!Object.keys(support).length && this.renderMethodSupportTable()}
        <h2 className={`storage_clear${!storageSupport ? '_disabled' : ''}`}>
          Browser Storage Clear
          {!storageSupport && <span>Cannot clear storage.</span>}
          <Button
            isSecondary
            disabled={!storageSupport}
            onClick={this.onClearStorage}
          >
            Clear
          </Button>
        </h2>
        {!!this.props.domainsToCheck.length && (
          <h2 className="check_domains">
            Check API Accessibility
            <Button isSecondary onClick={this.onCheckDomains}>
              Check
            </Button>
          </h2>
        )}
        {endpointsSupport !== null && (
          <h2
            className={`supported_message ${
              endpointsSupport ? '' : 'in'
            }compatible`}
          >
            {`APIs ${
              endpointsSupport ? 'are accessible.' : 'cannot be reached.'
            }`}
            <Icon
              name={`${endpointsSupport ? 'circle_check' : 'circle_with_x'}`}
              size="3rem"
              className={`icon--${endpointsSupport ? 'green' : 'red'}`}
            />
          </h2>
        )}
        <h2 className="error_dump">
          Debug Information
          {this.renderDebugOutput()}
        </h2>
      </section>
    );
  }
}

export { CompatibilityView };

module.exports = {
  containerWidth: 'calc(100% - 25rem)',
  '40ch': '40ch',
  8.2: '2.2rem',
  8.4: '2.4rem',
  8.8: '2.8rem',
  12.2: '3.2rem',
  12.6: '3.6rem',
  20.2: '5.2rem',
  25: '6.4rem',
  32.4: '8.4rem',
  64.6: '16.6rem',
  69.5: '17.5rem',
  84: '21rem',
  88.2: '22.5rem',
  96: '24.5rem',
  98: '25rem',
  110: '28rem',
  128: '32rem',
  250: '23rem',
  130: '13rem',
  150: '15rem',
  160: '40rem',
  200: '50rem',
  240: '60rem',
  256: '64rem',
  800: '20rem',
  '1/10': '10%',
  '80-screen': '80vw',
  '90-screen': '90vw',
};

import CompatibilityTests, { logErr } from './CompatibilityTests';
import { CompatibilityView } from './CompatibilityView';

/**
 * @classdesc Utility to assess various aspects of user environment for compatibility.
 *            Provides static methods for promise-based abstractions of various browser tests.
 *
 * @example
 *
 * // Check if localStorage is supported.
 * CompatibilityCheck.checkBrowserStorage()
 *   .then(result => {
 *     if (result.compatible) {
 *       // Proceed with using localStorage, etc.
 *     } else {
 *       // Handle missing API.
 *     }
 *   });
 *
 * // Run several checks for methods, APIs, custom requirement.
 * onEnvCheck() {
 *   const { checkDomains, checkBrowser, checkBrowserStorage, customCheck } = CompatibilityCheck;
 *   const serviceWorkerSupported = function() {
 *     return !!window.navigator.serviceWorker;
 *   };
 *
 *   Promise.all(
 *     checkDomains(['https://foo.bar.com']), checkBrowser(), checkBrowserStorage(), customCheck(serviceWorkerSupported))
 *   ).then(results => {
 *     // Parse results array, etc.
 *   }).catch(error => {
 *     // Handle error condition.
 *   });
 * }
 */
class CompatibilityCheck {
  /**
   * @member {function}
   * @static
   * @desc Checks one or more domains for accessibility.
   *       If multiple domains are provided to evaluate, the test will fail if any single domain is deemed inaccesible,
   *       regardless of other provided domains passing the test.
   * @param {Array.<string>} [domainList = []] Array of one or more domains to evaluate.
   * @return {Object.<Promise>} Returns resolved promise with assessment object.
   *                            Schema: {compatible: {boolean}, error: {?Object.<TypeError<SecurityError>>}}
   * @todo Extend this method to specify which domain(s) failed the check.
   */
  static checkDomains(domainList = []) {
    if (!domainList.length) {
      return Promise.reject(
        logErr('No domains specified to evaluate.', 'checkDomains'),
      );
    }
    return CompatibilityTests._checkDomains(domainList);
  }
  /**
   * @member {function}
   * @static
   * @desc Checks browser for required method/API support.
   * @return {Object.<Promise>} Returns resolved promise with compatible boolean, fault data and API availibility.
   *                            Schema: {compatible: {boolean}, error: {?Array<string>}, support: {Object.<T>}}
   *                            <T> Schema: {'XMLHttpRequest': <Boolean<methodSupported>>,
   *                                         'history': <Boolean<methodSupported>>,
   *                                         'cookie'; <Boolean<methodSupported>>,
   *                                         'postMessage': <Boolean<methodSupported>>,
   *                                         'userAgent': <Boolean<methodSupported>>}
   * Example return values:
   *
   * Full compatibilty - {compatible: true, error: null, support: {Object<T>}}
   * User is on IE9 - {compatible: false, error: 'userAgent', support: {Object<T>}}
   * User can't support messaging or history manipulation - {compatible: false,
   *                                                         error: ['history', 'postMessage'],
   *                                                         support: {Object<T>}}
   */
  static checkBrowser() {
    return Promise.resolve(CompatibilityTests._checkBrowser());
  }
  /**
   * @member {function}
   * @static
   * @desc Specific check for localStorage support.
   * @param {boolean} [resolveOnly = false] When true, error result will not not incur a rejected promise.
   * @return {Object.<Promise>} Returns promise with assessment object.
   *                            Schema: {compatible: <Boolean>,
   *                                     error: <Object.<string, <DOMException<SecurityError>>>>}
   */
  static checkBrowserStorage(resolveOnly = false) {
    return CompatibilityTests._checkBrowserStorage(resolveOnly);
  }
  /**
   * @member {function}
   * @static
   * @desc Optional additional check(s) to perform, provided as a simple function.
   * @param {<Array<Function>>} [checksArr = []] Array of one or more functions to evaluate.
   * @return {Object.<Promise>} Returns resolved promise with array of function evaluation(s).
   *                            Schema: {<T>: {<*<function return value or caught exception>>}
   *                            <T> Schema: {<String<function name OR constructor name OR array index>>}
   */
  static customCheck(checksArr = []) {
    if (!checksArr.length) {
      return Promise.reject(
        logErr('No check functions specified to evaluate.', 'customCheck'),
      );
    }
    return Promise.resolve(CompatibilityTests._customChecks(checksArr));
  }
}

export { CompatibilityView };
export default CompatibilityCheck;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import get from 'lodash/get';
import { KEY_CODES } from 'legacy-src/utility/utils';

/**
 * It supports:
 * react-router/Link and DOM elements with onClick or href
 * */
const A11y = (props) => {
  const { children, ignoreEvents = false, onKeyDown: customKeyDown } = props;
  const {
    props: { onClick },
  } = children;

  const onKeyDown = (event) => {
    if (ignoreEvents) return;

    if ([KEY_CODES.return, KEY_CODES.space].includes(event.keyCode)) {
      event.stopPropagation();
      event.preventDefault();

      if (onClick) {
        onClick(event);
      } else if (event.target.click) {
        event.target.click(event);
      }
    }

    customKeyDown && customKeyDown(event);
  };

  const className = cn(get(children, 'props.className'));
  return React.cloneElement(children, { onKeyDown, className });
};

A11y.propTypes = {
  children: PropTypes.any.isRequired,
};

export default A11y;

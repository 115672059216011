const browser = {
  getUserAgent() {
    return window.navigator.userAgent;
  },
  userAgentContains(browserName = '') {
    const bName = browserName.toLowerCase();
    return this.getUserAgent().toLowerCase().indexOf(bName) > -1;
  },
  isIOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (this.userAgentContains('Mac') && 'ontouchend' in document)
    );
  },
  isOSX() {
    return this.userAgentContains('Macintosh');
  },
  isIPad() {
    const { platform, maxTouchPoints } = window.navigator;
    return (
      platform === 'iPad' || (platform === 'MacIntel' && maxTouchPoints > 0)
    );
  },
  isIPhone() {
    return this.userAgentContains('iphone');
  },
  isFirefox() {
    return this.userAgentContains('firefox');
  },
  isInternetExplorer() {
    return this.userAgentContains('trident');
  },
  isIE() {
    const ua = this.getUserAgent().toLowerCase();

    const msie = ua.indexOf('msie');
    if (msie > 0) {
      // IE 10 or older
      return true;
    }

    const trident = ua.indexOf('trident/');
    if (trident > 0) {
      // IE 11
      return true;
    }

    // other browser
    return false;
  },
  isEdge() {
    const ua = this.getUserAgent().toLowerCase();
    const edge = ua.indexOf('edge');
    return edge > 0;
  },
  isChrome() {
    // IE11 returns undefined for window.chrome
    // and new Opera 30 outputs true for window.chrome
    // but needs to check if window.opr is not undefined
    // and new IE Edge outputs to true for window.chrome
    // and if not iOS Chrome check
    const isChromium = window.chrome;
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = typeof window.opr !== 'undefined';
    const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
    const isIOSChrome = winNav.userAgent.match('CriOS');
    return (
      (isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false) ||
      isIOSChrome
    );
  },
  isOpera() {
    return this.userAgentContains(' OPR/');
  },
  isChromeBook() {
    return /(CrOS)/.test(this.getUserAgent());
  },
};

export default browser;

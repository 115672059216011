import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'legacy-packages/ft-cb';
import cn from 'classnames';

import './ColumnSort.scss';

const TRANSITIONS = {
  descending: 'ascending',
  ascending: 'unsorted',
  unsorted: 'descending',
};

const REVERSED_TRANSITIONS = {
  ascending: 'descending',
  descending: 'unsorted',
  unsorted: 'ascending',
};

const getSortOrder = (selection) => {
  const a = selection.split('_');
  return a[a.length - 1];
};

const getColumnName = (selection) =>
  selection.split('_').slice(0, -1).join('_');

const ColumnSort = (props) => {
  const {
    onChange,
    selection,
    columnId,
    isDisabled,
    isReversed,
    sortIconColor,
    className,
  } = props;

  const notEmpty = selection && selection.length;
  const currentSortColumn = notEmpty ? getColumnName(selection) : '';
  const currentSortOrder = notEmpty ? getSortOrder(selection) : 'unsorted';
  const isActiveSortColumn = currentSortColumn
    ? columnId === currentSortColumn
    : false;

  const buttonRef = useRef(null);

  const ariaSorting =
    notEmpty && isActiveSortColumn ? getSortOrder(selection) : 'none';

  const toggle = () => {
    if (isActiveSortColumn) {
      if (isReversed) {
        onChange(`${columnId}_${REVERSED_TRANSITIONS[currentSortOrder]}`);
      } else {
        onChange(`${columnId}_${TRANSITIONS[currentSortOrder]}`);
      }
    } else if (isReversed) {
      onChange(`${columnId}_ascending`);
    } else {
      onChange(`${columnId}_descending`);
    }
  };

  useEffect(() => {
    if (isActiveSortColumn) {
      buttonRef && buttonRef.current && buttonRef.current.focus();
    }
  }, [isActiveSortColumn, buttonRef]);

  let iconName = isActiveSortColumn
    ? `sort_${currentSortOrder}`
    : 'sort_unsorted';
  if (sortIconColor) {
    iconName = `${iconName}_${sortIconColor}`;
  }

  return (
    <button
      className={cn('ColumnSort', `${isDisabled ? 'disabled' : ''}`, className)}
      disabled={isDisabled}
      onClick={toggle}
      role="columnheader"
      aria-sort={ariaSorting}
      ref={buttonRef}
    >
      {props.children}
      <Icon name={iconName} />
    </button>
  );
};

ColumnSort.propTypes = {
  selection: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  columnId: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  sortIconColor: PropTypes.string,
  className: PropTypes.string,
};

ColumnSort.defaultProps = {
  className: '',
};

export default ColumnSort;

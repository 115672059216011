import IconList from 'chameleon/ui-stack/utilities/icon-list';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './styles.css';

/**
 * Draws a SVG
 * */
const Icon = (props) => {
  const {
    id,
    name,
    className,
    width,
    height,
    accessibility,
    role,
    svgId,
    hasAriaLabelledBy,
    onClick,
    onKeyPress,
    noRole,
    noDesc,
  } = props;

  const {
    title,
    ariaHidden,
    ariaLabel,
    ariaLabelledBy,
    altText,
    tabIndex,
    ...otherAccessibility
  } = accessibility;
  let iconIdentifier = id || name;
  const accessibilityProps = {
    ...(!noRole && { role: role || 'img' }),
    alt: altText,
    'aria-hidden': ariaHidden,
    'aria-label': ariaLabel,
    ...(!ariaLabel &&
      hasAriaLabelledBy && {
        'aria-labelledby': ariaLabelledBy || `desc_${iconIdentifier}`,
      }),
    tabIndex,
    ...otherAccessibility,
  };

  const iconData = IconList[iconIdentifier];
  if (!iconData) {
    throw new Error(`${id} icon was not found.`);
  }

  const pathClassName = className && `fill-current ${className}`;

  const {
    viewBox,
    body,
    width: defaultWidth,
    height: defaultHeight,
  } = iconData;
  return (
    <svg
      data-test-id={props['data-test-id'] || id}
      viewBox={viewBox}
      width={`${width || defaultWidth}px`}
      height={`${height || defaultHeight}px`}
      className={cn('icon fill-current', className)}
      id={svgId}
      onClick={onClick}
      onKeyPress={onKeyPress}
      {...accessibilityProps}
      style={{ minWidth: `${width || defaultWidth}px` }}
    >
      {!noDesc && iconIdentifier && (
        <Fragment>
          <desc id={`desc_${iconIdentifier}`}>
            {`${title || iconIdentifier}`}
          </desc>
        </Fragment>
      )}
      {body(pathClassName)}
    </svg>
  );
};

Icon.propTypes = {
  /**
   * Icon identifier
   * */
  id: PropTypes.string,
  name: PropTypes.string,
  accessibility: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  hasAriaLabelledBy: PropTypes.bool,
  noRole: PropTypes.bool,
  noDesc: PropTypes.bool,
};

Icon.defaultProps = {
  id: '',
  name: '',
  accessibility: {
    title: null,
    ariaLabel: null,
    ariaLabelledBy: null,
    ariaHidden: null,
    altText: null,
    tabIndex: null,
  },
  width: null,
  height: null,
  className: '',
  onClick: null,
  onKeyPress: null,
  hasAriaLabelledBy: true,
  noRole: false,
  noDesc: false,
};

export default Icon;

import { isFunction } from 'lodash';

/**
 * Response object variable for method reference.
 */
const BASE_RETURN_OBJECT = {
  compatible: true,
  error: null,
};

/**
 * Test data for localStorage and cookie checks.
 */
const STORAGE_TEST_DATA = {
  KEY: `_FT_BROWSER_STORAGE_TEST_${Date.now()}`,
  VALUE: `_FT_BROWSER_TEST_VAL_${Math.random()}`,
};

/**
 * Error utility for CompatibilityCheck.
 * @param {string} [msg = ''] Error message.
 * @param {?string} [methodName = null] Optional method name to include.
 */
const logErr = function (msg = '', methodName = null) {
  return {
    compatible: false,
    error: `CompatibilityCheck${
      methodName ? `#${methodName} - ` : ' - '
    }${msg}`,
  };
};

/**
 * @classdesc Various tests to assess user environment compatibility.
 *            Intended to be used by CompatibilityCheck.js only.
 */
class CompatibilityTests {
  /**
   * @desc Loops through domains and wraps them in fetch() queries.
   *       Returns resolved or rejected promise. Any failed domain check will result in a
   *       rejected promise (even if other domains passed the check).
   * @return {Object.<Promise>}
   */
  static _checkDomains(domains) {
    const requests = domains.map((domain) =>
      fetch(domain, { mode: 'no-cors' }),
    );

    return new Promise((resolve, reject) => {
      Promise.all(requests)
        .then(() => {
          resolve(BASE_RETURN_OBJECT);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }
  /**
   * Checks browser for required methods/properties.
   */
  static _checkBrowser() {
    /**
     * Tests for cookie read/write ability.
     * @return {boolean}
     */
    const cookieCheck = () => {
      const testCookie = `${STORAGE_TEST_DATA.KEY}=${STORAGE_TEST_DATA.VALUE}`;

      try {
        document.cookie = testCookie;
        /**
         * If the test cookie wasn't set or setting a cookie causes an error, return false;
         */
        if (!document.cookie.includes(testCookie)) {
          return false;
        }
        /**
         * Remove test cookie, or invoke catch block and return false if we cannot.
         */
        document.cookie = `${testCookie}; expires=Thu, 1 Jan 1970 00:00:00 UTC`;
      } catch (e) {
        return false;
      }

      return true;
    };
    const browserProps = {
      XMLHttpRequest: !!window.XMLHttpRequest,
      history: !!window.history,
      postMessage: !!window.postMessage,
      userAgent: !/msie\s?[1-9]/i.test(window.navigator.userAgent),
      cookie: cookieCheck(),
    };
    const errorArr = Object.keys(browserProps).filter((prop) => {
      if (browserProps[prop] === false) {
        return { [prop]: browserProps[prop] };
      }
    });

    return errorArr.length
      ? {
          compatible: false,
          error: errorArr,
          support: browserProps,
        }
      : {
          ...BASE_RETURN_OBJECT,
          support: browserProps,
        };
  }
  /**
   * Checks for ability to access and utilize window.localStorage
   */
  static _checkBrowserStorage(resolveOnly) {
    return new Promise((resolve, reject) => {
      try {
        const APIs = {
          _setItem: window.localStorage.setItem(
            STORAGE_TEST_DATA.KEY,
            STORAGE_TEST_DATA.VALUE,
          ),
          _getItem: window.localStorage.getItem(STORAGE_TEST_DATA.KEY),
          _removeItem: window.localStorage.removeItem(STORAGE_TEST_DATA.KEY),
        };
        /**
         * Check if the test value was set, and is the expected value.
         */
        if (APIs._getItem !== STORAGE_TEST_DATA.VALUE) {
          resolveOnly
            ? resolve({
                compatible: false,
                error: 'LOCALSTORAGE ERROR - CANNOT READ/WRITE',
              })
            : reject(new Error('LOCALSTORAGE ERROR - CANNOT READ/WRITE'));
        } else {
          resolve(BASE_RETURN_OBJECT);
        }
      } catch (error) {
        resolveOnly
          ? resolve({
              compatible: false,
              error,
            })
          : reject(new Error(error));
      }
    });
  }
  /**
   * Invoke provided function(s) and collect return values or exceptions.
   */
  static _customChecks(checks) {
    if (checks.length) {
      /**
       * Returns function name or array position.
       * @return {string}
       */
      const getFuncName = (func, idx) => {
        /**
         * Skip over "function" or "Function" to prevent use of the indistinct object keys.
         */
        if (func.name && !/function/i.test(func.name)) {
          return func.name;
        }

        return `func@${idx}`;
      };
      /**
       * Returns function return value or invocation exception.
       * @return {*}
       */
      const getFuncValue = (func) => {
        let evaluation;

        try {
          evaluation = func();
        } catch (error) {
          evaluation = error;
        }

        return evaluation;
      };

      return checks.map((func, idx) => {
        if (isFunction(func)) {
          try {
            return { [getFuncName(func, idx)]: getFuncValue(func) };
          } catch (error) {
            return { [`ERROR@${idx}`]: error };
          }
        }
      });
    }
  }
}

export { logErr };
export default CompatibilityTests;

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import ButtonWithIcon from 'chameleon/ui-stack/ui/buttons/button-with-icon';
import styles from './styles.css';
import './style.scss';

/**
 * This component is created to customize
 * Appcues widget.
 */
const QuickTips = () => {
  useEffect(() => {
    const renderIcon = (icon) => {
      ReactDOM.render(
        <ButtonWithIcon
          isIcon
          isSecondary
          tabIndex="-1"
          className={cn('appcues-widget-icon', styles.quickTipsButton)}
          accessibility={{
            title: 'Announcements',
          }}
          icon="bell"
        />,
        icon,
      );
    };

    const [existingIcon] = document.getElementsByClassName(
      'appcues-widget-icon',
    );
    if (existingIcon) {
      renderIcon(existingIcon);
      return;
    }

    const observer = new MutationObserver((mutations_list) => {
      mutations_list.forEach((mutation) => {
        mutation.addedNodes.forEach((added_node) => {
          if (
            added_node.classList &&
            Array.from(added_node.classList).includes('appcues-widget-icon')
          ) {
            const [icon] = document.getElementsByClassName(
              'appcues-widget-icon',
            );
            if (icon) {
              renderIcon(icon);
              observer.disconnect();
            }
          }
        });
      });
    });

    observer.observe(document.querySelector('#app-cues-widget-quick-tips'), {
      subtree: true,
      childList: true,
    });
  });

  return <div className="quick-tips" id="app-cues-widget-quick-tips" />;
};

QuickTips.displayName = 'QuickTips';

export default QuickTips;

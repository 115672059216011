export const MCQ_WIDGET_JSON = {
  options: [
    {
      label: '&nbsp;',
      value: '0',
    },
    {
      label: '&nbsp;',
      value: '1',
    },
  ],
  stimulus: '&nbsp;',
  type: 'mcq',
  ui_style: {},
  validation: {
    scoring_type: 'exactMatch',
    valid_response: {},
  },
};

export const SHARED_PASSAGE_JSON = {
  feature_id: '7004',
  type: 'sharedpassage',
  content: '&nbsp;',
  heading: '',
};

export const MCQ_WIDGET_TEMPLATE = '9e8149bd-e4d8-4dd6-a751-1a113a4b9163';

export const FRQ_WIDGET_JSON = {
  type: 'longtextV2',
  stimulus: '&nbsp;',
  formatting_options: [
    'bold',
    'italic',
    'underline',
    '|',
    'unorderedList',
    'orderedList',
    'image',
  ],
};

export const FILE_UPLOAD_WIDGET_JSON = {
  type: 'fileupload',
  stimulus: '&nbsp;',
  max_files: 12,
  allow_jpg: true,
  allow_png: true,
  allow_gif: true,
  allow_pdf: true,
  allow_ms_word: true,
  allow_ms_excel: true,
  allow_ms_powerpoint: true,
};

export const AUDIO_WIDGET_JSON = {
  type: 'audio',
  stimulus: '&nbsp;',
  ui_style: {
    type: 'button',
  },
};

export const FRQ_WIDGET_TEMPLATE = '1e6039f8-0676-495d-aca9-108710a51ce5';
export const FILE_UPLOAD_WIDGET_TEMPLATE =
  '40f17271-9190-469e-b9da-e9bb57352b62';
export const AUDIO_WIDGET_TEMPLATE = '1ed372fd-78fc-4681-8898-338a81a176e5';

export const MATHEMATICS_CHARACTER_MAP_ICON = 'Ω';
export const MATHEMATICS_CHARACTER_MAPS = [
  '°',
  '∞',
  '≤',
  '≥',
  '≠',
  '≈',
  'π',
  'Δ',
  'Σ',
  'Χ',
  'α',
  'β',
  'ε',
  'θ',
  'κ',
  'λ',
  'μ',
  'ν',
  'ρ',
  'σ',
  'τ',
  'ω',
  'x̄',
  'p̂',
  'ŷ',
  '→',
  '⇄',
];
export const MATHEMATICS_FORMATTING_OPTIONS = [
  'bold',
  'italic',
  'underline',
  'superscript',
  'subscript',
  'undo',
  'redo',
  'characterMathMap',
  'unorderedList',
  'orderedList',
  'image',
];
export const COMPUTER_SCIENCE_FORMATTING_OPTIONS = [
  'bold',
  'italic',
  'underline',
  '|',
  'unorderedList',
  'orderedList',
  'image',
  '|',
  'codeInline',
  'codeEditor',
];

export const FOREIGN_LANGUAGE_FORMATTING_OPTIONS = [
  'bold',
  'italic',
  'underline',
  '|',
  'unorderedList',
  'orderedList',
  'charactermap',
  'image',
];
export const FRENCH_CHARACTER_MAPS = [
  'Ç',
  'é',
  'â',
  'ê',
  'î',
  'ô',
  'û',
  'à',
  'è',
  'ù',
  'ë',
  'ï',
  'ü',
];
export const GERMAN_CHARACTER_MAPS = ['Ä', 'ä', 'Ö', 'ö', 'Ü', 'ü', 'ß'];
export const ITALIAN_CHARACTER_MAPS = [
  'À',
  'à',
  'È',
  'è',
  'É',
  'é',
  'Ì',
  'ì',
  'Ò',
  'ò',
  'Ù',
  'ù',
];
export const SPANISH_CHARACTER_MAPS = [
  'Á',
  'á',
  'É',
  'é',
  'Í',
  'í',
  'Ó',
  'ó',
  'Ú',
  'ú',
  'Ü',
  'ü',
  'Ñ',
  'ñ',
  '¿',
  '¡',
  '«',
  '»',
];

import { useRef } from 'react';

const KEY_CODES = {
  DOWN: 40,
  UP: 38,
  LEFT: 37,
  RIGHT: 39,
  ENTER: 13,
  SPACE: 32,
};

const useTreeListBox = ({
  onExpand: onExpandCallback,
  onCollapse: onCollapseCallback,
  onClick: onClickCallback,
}) => {
  const selectedElement = useRef();

  const setCurrentElement = (elem) => {
    if (elem) {
      elem.focus();
      elem.setAttribute('aria-selected', true);
    }
  };

  const setParentProps = (parent, child) => {
    if (parent && child) {
      const childId = child.getAttribute('id');
      parent.setAttribute('aria-activedescendant', childId);
    }
  };

  const setHighlightedItem = (target, currentTarget) => {
    if (target) {
      selectedElement.current.setAttribute('aria-selected', false);
      //move to next child
      selectedElement.current = target;
      setCurrentElement(target);
      setParentProps(currentTarget, target);
    }
  };

  const onExpand = (event) => {
    if (event.target.getAttribute('aria-expanded') !== 'true') {
      onExpandCallback(event);
      event.target.setAttribute('aria-expanded', true);
    }
  };
  const onCollapse = (event) => {
    if (event.target.getAttribute('aria-expanded') === 'true') {
      onCollapseCallback(event);
      event.target.setAttribute('aria-expanded', false);
    }
  };
  const onMoveDown = (event) => {
    const { target, currentTarget } = event;
    let elementToHighlight;
    if (target === currentTarget) {
      elementToHighlight = target.querySelector('[role="treeitem"]');
    } else if (
      selectedElement.current &&
      selectedElement.current.getAttribute('aria-expanded') === 'true' &&
      !selectedElement.current.querySelector('[role="group"]')
    ) {
      const { nextSibling } = selectedElement.current;
      const { nextSibling: nextParentSibling } = selectedElement.current
        .closest('[role="group"]')
        .closest('[aria-expanded]');
      const sibling = nextSibling || nextParentSibling;
      if (sibling) {
        elementToHighlight = sibling;
      }
    } else if (
      selectedElement.current &&
      selectedElement.current.getAttribute('aria-expanded') === 'true'
    ) {
      elementToHighlight =
        selectedElement.current.querySelector('[role="treeitem"]');
    } else if (
      selectedElement.current &&
      selectedElement.current.parentNode.lastChild === selectedElement.current
    ) {
      const { nextSibling } =
        selectedElement.current.closest('[aria-expanded="true"]') || {};
      if (nextSibling) {
        elementToHighlight = nextSibling;
      }
    } else if (selectedElement.current) {
      const { nextSibling } = selectedElement.current;
      if (nextSibling) {
        elementToHighlight = nextSibling;
      }
    }

    if (elementToHighlight) {
      selectedElement.current = elementToHighlight;
      setHighlightedItem(elementToHighlight, event.currentTarget);
    }
  };
  const onMoveUp = (event) => {
    let elementToHighlight;
    if (selectedElement.current) {
      const { previousSibling } = selectedElement.current;
      if (
        previousSibling &&
        previousSibling.getAttribute('aria-expanded') === 'true'
      ) {
        const { lastChild } =
          previousSibling.querySelector('[role="group"]') || {};
        elementToHighlight = lastChild || previousSibling;
      } else if (previousSibling) {
        elementToHighlight = previousSibling;
      } else if (selectedElement.current.closest('[role="group"]')) {
        elementToHighlight = selectedElement.current
          .closest('[role="group"]')
          .closest('[aria-expanded]');
      }
    }

    if (elementToHighlight) {
      selectedElement.current = elementToHighlight;
      setHighlightedItem(elementToHighlight, event.currentTarget);
    }
  };

  const onClick = (event) => {
    onClickCallback && onClickCallback(event);
  };

  const onKeyUp = (event) => {
    const { which: keyCode } = event;

    let callback;

    switch (keyCode) {
      case KEY_CODES.UP:
        callback = onMoveUp;
        break;
      case KEY_CODES.DOWN:
        callback = onMoveDown;
        break;
      case KEY_CODES.LEFT:
        callback = onCollapse;
        break;
      case KEY_CODES.RIGHT:
        callback = onExpand;
        break;
      case KEY_CODES.SPACE:
      case KEY_CODES.ENTER:
        callback = onClick;
        break;
    }

    if (callback) {
      callback(event);
    }
  };

  const treeItemProps = {
    onKeyUp,
  };

  return {
    treeItemProps,
  };
};

useTreeListBox.propTypes = {};

export default useTreeListBox;

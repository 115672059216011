import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './index.scss';

const PoweredBy = (props) => {
  const classes = cn(['powered_by', { 'powered_by--styled': props.styled }]);

  return (
    <a href="http://www.academicmerit.com" target="_blank" className={classes}>
      <svg viewBox="0 0 1048 204" aria-label="Powered By AcademicMerit">
        <g>
          <path d="M33.8533 116.903L33.6767 116.903L25.59 145.787L42.12 145.787L33.8533 116.903ZM21.77 158.76L18.48 170.23L0 170.23L19.99 103.577L47.0067 103.577L67.27 170.23L49.14 170.23L45.6767 158.76L21.77 158.76Z" />
          <path d="M127.253 144.987L127.253 146.943C127.253 154.2 127.2 160.8 121.1 165.867C115.08 170.8 107.7 170.8 100 170.76C81.8433 170.8 68.6 169.9 68.6 145.52L68.6033 128.28C68.6033 106.2 80.4 103 100 103.043C118.277 103 126.8 104.9 127.2 124.903L127.167 127.48L108.857 127.48L108.857 124.903C108.237 119 104.1 118.5 98 118.507C87.8833 118.5 86.9 121.6 86.9 131.123L86.9067 141.523C86.9067 151.5 87.4 155.3 98 155.297C105.393 155.3 108.9 154.8 108.9 146.943L108.943 144.987L127.253 144.987Z" />
          <path d="M162.533 116.903L162.36 116.903L154.27 145.787L170.8 145.787L162.533 116.903ZM150.45 158.76L147.16 170.23L128.68 170.23L148.67 103.577L175.69 103.577L195.95 170.23L177.82 170.23L174.357 158.76L150.45 158.76Z" />
          <path d="M219.05 154.757L234.607 154.757C241.18 154.8 243.6 150.4 243.6 143.387L243.58 129.437C243.58 120.8 240.8 119 234.6 119.04L219.05 119.04L219.05 154.757ZM201.28 103.577L235.05 103.577C253.62 103.6 261.9 110.5 261.9 129.437L261.883 143.387C261.883 161.6 256 170.2 237.4 170.23L201.28 170.23L201.28 103.577Z" />
          <path d="M286.77 130.323L315.21 130.323L315.21 142.677L286.77 142.677L286.77 155.65L317.697 155.65L317.697 170.23L268.993 170.23L268.993 103.577L317.073 103.577L317.073 118.15L286.77 118.15L286.77 130.323Z" />
          <path d="M344.09 170.23L326.317 170.23L326.317 103.577L354.487 103.577L367.193 145.43L367.82 145.43L379.903 103.577L408.787 103.577L408.787 170.23L391.01 170.23L392.79 119.13L392.167 119.13L375.28 170.23L359.91 170.23L342.85 119.13L342.313 119.13L344.09 170.23Z" />
          <rect x="419.6" y="103.6" width="17.8" height="66.7" />
          <path d="M504.673 144.987L504.673 146.943C504.673 154.2 504.6 160.8 498.5 165.867C492.493 170.8 485.1 170.8 477.4 170.76C459.263 170.8 446 169.9 446 145.52L446.017 128.28C446.017 106.2 457.8 103 477.4 103.043C495.697 103 504.2 104.9 504.6 124.903L504.58 127.48L486.273 127.48L486.273 124.903C485.653 119 481.5 118.5 475.4 118.507C465.303 118.5 464.3 121.6 464.3 131.123L464.323 141.523C464.323 151.5 464.8 155.3 475.4 155.297C482.81 155.3 486.4 154.8 486.4 146.943L486.367 144.987L504.673 144.987Z" />
          <path d="M554.257 170.23L546.793 170.23L521.56 109.263L521.38 109.263L521.38 170.23L513.827 170.23L513.827 103.577L526.89 103.577L550.44 160.627L574.08 103.577L587.14 103.577L587.14 170.23L579.59 170.23L579.59 109.263L579.41 109.263L554.257 170.23Z" />
          <path d="M604.113 132.903L637.88 132.903L637.88 139.12L604.113 139.12L604.113 163.557L639.393 163.557L639.393 170.23L596.56 170.23L596.56 103.577L639.393 103.577L639.393 110.24L604.113 110.24L604.113 132.903Z" />
          <path d="M679.21 136.9C690.847 136.5 692.3 133 692.3 121.97C692.27 113.4 691.1 110.2 679.2 110.24L655.923 110.24L655.923 136.9L679.21 136.9ZM655.923 170.23L648.367 170.23L648.367 103.577L679.117 103.577C693.51 103.6 699.8 107.6 699.8 122.77C699.823 132.1 698.8 139 688.2 140.367L688.18 140.547C695.65 141.1 698.9 145.3 698.9 152.54L698.933 170.23L691.377 170.23L691.377 156.093C691.377 147.7 689.3 143.6 680.2 143.563L655.923 143.563L655.923 170.23Z" />
          <rect x="710.5" y="103.6" width="7.5" height="66.7" />
          <path d="M753.41 170.23L745.86 170.23L745.86 110.24L724.267 110.24L724.267 103.577L775.007 103.577L775.007 110.24L753.41 110.24L753.41 170.23Z" />
          <path d="M891.67 85.26C892.673 87 911.4 119.5 916.1 127.797C917.01 129.4 916.8 129.8 915 130.097C893.983 133.6 873.9 139.6 855 147.757C853.857 148.3 853.2 148.4 854.2 146.687C855.097 145 858.7 138.6 858.7 138.55C858.68 138.6 888.2 87.2 889.4 85.2133C890.58 83.2 890.6 83.2 891.7 85.26ZM997.563 138.667C1014.43 138.7 1030.9 140.2 1046.9 143.36C1047.62 143.5 1047.7 143.3 1047 142.99C1021.41 133.3 994 127.7 965.5 126.78C962.977 126.6 963 126.6 961.9 124.793C960.91 123.1 891.8 2.7 891.8 2.65666C890.517 0.4 890.5 0.4 889.3 2.61C889.277 2.6 776.8 199.8 775.7 201.763C774.653 203.6 774.7 203.9 776.8 203.943C786.41 203.9 824.3 203.9 826.3 203.943C828.58 203.9 828.6 203.7 830.4 201.923C857.647 177.9 890 159.8 925.7 149.367C927.91 148.9 927.9 148.8 929 150.743C929.917 152.3 952.5 192.6 957.6 201.807C958.547 203.6 958.7 203.9 961 203.943C970.213 203.9 1002.6 203.9 1004.4 203.943C1006.82 203.9 1006.8 203.7 1005.7 201.68C999.843 191.5 972.9 144.4 971.9 142.557C970.747 140.5 970.8 140.3 973 140.09C981.063 139.3 989.3 138.7 997.6 138.667Z" />
          <path d="M1037.29 204L1036.9 197.91C1036.87 197.1 1036.9 196.1 1036.8 195.02L1036.72 195.02C1036.45 195.9 1036.2 197.2 1035.8 198.1L1033.98 203.85L1031.84 203.85L1029.98 197.947C1029.79 197.2 1029.5 195.9 1029.3 195.02L1029.14 195.02C1029.14 196 1029.1 197 1029.1 197.91L1028.68 204L1026.7 204L1027.47 193.647L1030.55 193.647L1032.34 198.71C1032.57 199.5 1032.8 200.3 1033 201.337L1033.06 201.337C1033.33 200.4 1033.6 199.5 1033.8 198.747L1035.57 193.647L1038.54 193.647L1039.34 204L1037.29 204ZM1026.1 193.647L1026.1 195.36L1022.78 195.36L1022.78 204L1020.73 204L1020.73 195.36L1017.42 195.36L1017.42 193.647L1026.1 193.647Z" />
          <g>
            <path d="M15.8826 57.4713L19.2702 57.4713C22.4363 57.5 24.8 56.8 26.4 55.5949C27.9494 54.3 28.7 52.5 28.7 50.1316C28.7354 47.7 28.1 45.9 26.8 44.7846C25.442 43.6 23.4 43.1 20.6 43.0576L15.8826 43.0576L15.8826 57.4713ZM39.1306 49.7663C39.1306 55 37.5 59 34.2 61.7556C30.9662 64.5 26.3 65.9 20.3 65.907L15.8826 65.907L15.8826 83.177L5.58708 83.177L5.58708 34.6219L21.0968 34.6219C26.9863 34.6 31.5 35.9 34.5 38.4246C37.5974 41 39.1 44.7 39.1 49.7663Z" />
            <path d="M92.3686 58.833C92.3686 66.9 90.4 73 86.4 77.365C82.4051 81.7 76.7 83.8 69.3 83.8412C61.814 83.8 56.1 81.7 52.1 77.365C48.1309 73 46.1 66.8 46.1 58.7666C46.1382 50.7 48.1 44.5 52.1 40.2512C56.1293 36 61.9 33.9 69.3 33.858C76.7813 33.9 82.5 36 86.4 40.301C90.3925 44.6 92.4 50.8 92.4 58.833ZM56.932 58.833C56.932 64.3 58 68.3 60 71.088C62.0797 73.8 65.2 75.2 69.3 75.2063C77.4677 75.2 81.6 69.7 81.6 58.833C81.5748 47.9 77.5 42.4 69.3 42.4266C65.2237 42.4 62.1 43.8 60.1 46.5614C57.9726 49.3 56.9 53.4 56.9 58.833Z" />
            <path d="M149.06 83.177L137.337 83.177L130.761 57.6706C130.517 56.8 130.1 54.9 129.5 52.0413C128.929 49.2 128.6 47.3 128.5 46.3123C128.37 47.5 128 49.4 127.5 52.0745C126.975 54.7 126.6 56.6 126.3 57.737L119.735 83.177L108.044 83.177L95.6565 34.6219L105.786 34.6219L111.997 61.1246C113.081 66 113.9 70.3 114.4 73.8446C114.487 72.6 114.8 70.6 115.3 67.9828C115.744 65.3 116.2 63.3 116.6 61.822L123.687 34.6219L133.418 34.6219L140.492 61.822C140.802 63 141.2 64.9 141.7 67.4016C142.119 69.9 142.5 72.1 142.7 73.8446C142.939 72.1 143.3 70 143.8 67.385C144.267 64.8 144.7 62.7 145.1 61.1246L151.286 34.6219L161.415 34.6219L149.06 83.177Z" />
            <path d="M195.49 83.177L167.526 83.177L167.526 34.6219L195.49 34.6219L195.49 43.0576L177.822 43.0576L177.822 53.7185L194.261 53.7185L194.261 62.1542L177.822 62.1542L177.822 74.6749L195.49 74.6749L195.49 83.177Z" />
            <path d="M215.915 56.1761L219.236 56.1761C222.491 56.2 224.9 55.6 226.4 54.5487C227.993 53.5 228.8 51.8 228.8 49.4342C228.768 47.1 228 45.5 226.4 44.5189C224.81 43.5 222.4 43.1 219 43.0576L215.915 43.0576L215.915 56.1761ZM215.915 64.5454L215.915 83.177L205.62 83.177L205.62 34.6219L219.768 34.6219C226.366 34.6 231.2 35.8 234.4 38.2253C237.58 40.6 239.2 44.3 239.2 49.1685C239.163 52 238.4 54.6 236.8 56.7905C235.233 59 233 60.8 230.1 62.0213C237.436 72.9 242.2 80 244.4 83.177L232.986 83.177L221.395 64.5454L215.915 64.5454Z" />
            <path d="M278.485 83.177L250.521 83.177L250.521 34.6219L278.485 34.6219L278.485 43.0576L260.817 43.0576L260.817 53.7185L277.257 53.7185L277.257 62.1542L260.817 62.1542L260.817 74.6749L278.485 74.6749L278.485 83.177Z" />
            <path d="M328.9 58.4345C328.9 66.4 326.6 72.5 322.1 76.8004C317.526 81.1 311 83.2 302.4 83.177L288.615 83.177L288.615 34.6219L303.859 34.6219C311.785 34.6 317.9 36.7 322.3 40.8988C326.708 45.1 328.9 50.9 328.9 58.4345ZM318.206 58.7002C318.206 48.3 313.6 43.1 304.4 43.0576L298.91 43.0576L298.91 74.6749L303.328 74.6749C313.247 74.7 318.2 69.3 318.2 58.7002Z" />
            <path d="M356.632 34.6219L371.743 34.6219C378.629 34.6 383.6 35.6 386.7 37.5611C389.849 39.5 391.4 42.6 391.4 46.9101C391.404 49.8 390.7 52.2 389.4 54.0506C388 55.9 386.2 57 383.9 57.4049L383.932 57.737C387.009 58.4 389.2 59.7 390.6 61.5896C391.952 63.5 392.6 66 392.6 69.0953C392.633 73.5 391 77 387.8 79.4573C384.635 81.9 380.3 83.2 374.8 83.177L356.632 83.177L356.632 34.6219ZM366.928 53.8513L372.906 53.8513C375.695 53.9 377.7 53.4 379 52.5561C380.218 51.7 380.8 50.3 380.8 48.2718C380.843 46.4 380.2 45.1 378.8 44.2698C377.439 43.5 375.3 43.1 372.3 43.0576L366.928 43.0576L366.928 53.8513ZM366.928 62.0213L366.928 74.6749L373.636 74.6749C376.47 74.7 378.6 74.1 379.9 73.0475C381.264 72 381.9 70.3 381.9 68.0658C381.939 64 379.1 62 373.3 62.0213L366.928 62.0213Z" />
            <path d="M416.778 54.6152L426.907 34.6219L438 34.6219L421.892 64.2797L421.892 83.177L411.663 83.177L411.663 64.6118L395.556 34.6219L406.715 34.6219L416.778 54.6152Z" />
          </g>
        </g>
      </svg>
    </a>
  );
};

PoweredBy.propTypes = {
  styled: PropTypes.bool,
};

PoweredBy.defaultProps = {
  styled: false,
};

export default PoweredBy;

/**
 * Class for user roles
 * */
export const userRoles = {
  _roles: [],

  /*
   * Stores the roles as private
   * @param {array} roles
   * */
  set(roles) {
    this._roles = roles || this._roles;

    return this;
  },
  /**
   * Checks if user has admin role
   * @return {boolean} true/false
   * */
  isAdmin() {
    return this._roles.includes('admin');
  },
  /**
   * Checks if user has coordinator role
   * @return {boolean} true/false
   **/
  isCoordinator() {
    return this._roles.includes('coordinator');
  },
  /**
   * Checks if user has staff role
   * @return {boolean} true/false
   * */
  isStaff() {
    return this._roles.includes('staff');
  },
  /**
   * Checks if user has student role
   * @return {boolean} true/false
   * */
  isStudent() {
    return this._roles.includes('student');
  },
  /**
   * Checks if user has teacher role
   * @return {boolean} true/false
   * */
  isTeacher() {
    return this._roles.includes('teacher');
  },
  /**
   * Checks if user has demo role
   * @return {boolean} true/false
   * */
  isDemo() {
    return this._roles.includes('demo');
  },
};

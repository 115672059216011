import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import A11y from 'chameleon/ui-stack/utilities/a11y';
import getStateThemes, {
  ELEMENT_CATEGORIES,
} from 'chameleon/ui-stack/ui/base/theme';

const Button = forwardRef((props, ref) => {
  const {
    name,
    value,
    type,
    disabled,
    isRounded,
    accessibility,
    children,
    className,
    size,
    isLink,
    isPrimary,
    isDanger,
    isDangerOutline,
    isSuccess,
    isOutline,
    isSecondary,
    isIcon,
    isNaked,
    dataTestId = '',
    isGolden,
    isAlert,
    background,
    isWrap,
    ...otherProps
  } = props;

  const buttonProps = {
    name,
    value,
    disabled,
    type,
  };

  const classNames = cn(
    'font-normal font-content',
    {
      'cursor-pointer': !disabled,
      'rounded-20': isRounded,
      'px-4.2 py-2.8': !isIcon && size !== 'custom',

      'flex items-center justify-center p-0': isIcon,

      'w-8.4': isIcon && size === 'tiny',
      'w-12.2': isIcon && size === 'small',
      'w-12.6': isIcon && size === 'medium',
      'w-16': isIcon && size === 'large',

      'h-8.4': size === 'tiny',
      'h-12.2': size === 'small',
      'h-12.6': size === 'medium',
      'h-16': size === 'large',

      'whitespace-nowrap': isWrap,

      [getStateThemes({
        type: ELEMENT_CATEGORIES.NAKED,
        background,
        disabled,
        isLink,
      })]: isNaked,
      [getStateThemes({
        type: ELEMENT_CATEGORIES.OUTLINE,
        disabled,
        isLink,
        background,
      })]: isOutline,
      [getStateThemes({
        type: ELEMENT_CATEGORIES.PRIMARY,
        disabled,
      })]: isPrimary,
      [getStateThemes({
        type: ELEMENT_CATEGORIES.SECONDARY,
        disabled,
        isLink,
        background,
      })]: isSecondary,
      [getStateThemes({
        type: ELEMENT_CATEGORIES.SUCCESS,
        disabled,
      })]: isSuccess,
      [getStateThemes({
        type: ELEMENT_CATEGORIES.DANGER,
        disabled,
      })]: isDanger,
      [getStateThemes({
        type: ELEMENT_CATEGORIES.GOLDEN,
        disabled,
      })]: isGolden,
      [getStateThemes({
        type: ELEMENT_CATEGORIES.ALERT,
        disabled,
        isLink,
      })]: isAlert, //@todo - this is not a state described in figma, should be revised
      //@todo - visit with @Seung
      'bg-white border-b border-t border-r border-l border-red-500 text-red-500':
        !disabled && isDangerOutline,
    },
    className,
  );
  return (
    <A11y>
      <button
        ref={ref}
        className={classNames}
        data-test-id={dataTestId}
        {...buttonProps}
        {...accessibility}
        {...otherProps}
      >
        {children}
      </button>
    </A11y>
  );
});

Button.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  isLink: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isDanger: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isIcon: PropTypes.bool,
  isNaked: PropTypes.bool,
  isOutline: PropTypes.bool,
  isGolden: PropTypes.bool,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  accessibility: PropTypes.shape({
    title: PropTypes.string,
    'aria-label': PropTypes.string,
    'aria-labelledby': PropTypes.string,
  }),
  //custom - must not be used
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large', 'custom']),
  disabled: PropTypes.bool,
  isDangerOutline: PropTypes.bool,
  background: PropTypes.string,
  isAlert: PropTypes.bool,
  isWrap: PropTypes.bool,
  isRounded: PropTypes.bool,
};

Button.defaultProps = {
  name: '',
  value: '',
  isLink: false,
  isPrimary: false,
  isDanger: false,
  isOutline: false,
  isGolden: false,
  type: 'button',
  isSecondary: false,
  isIcon: false,
  isNaked: false,
  isSuccess: false,
  className: '',
  accessibility: {
    title: null,
    'aria-label': null,
    'aria-labelledby': null,
  },
  size: 'small',
  disabled: false,
  isDangerOutline: false,
  background: '',
  isAlert: false,
  isWrap: true,
  isRounded: true,
};

Button.displayName = 'Button';

export default Button;

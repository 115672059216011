import { ELEMENT_CATEGORIES } from './constants';

const backgrounds = {
  [ELEMENT_CATEGORIES.NAKED]: {
    default: 'bg-none',
    white: 'bg-white hover:bg-opacity-100 active:bg-opacity-100',
  },
  [ELEMENT_CATEGORIES.OUTLINE]: {
    default: 'bg-none',
    white: 'bg-white',
  },
  [ELEMENT_CATEGORIES.PRIMARY]: {
    default: 'bg-blue-700',
  },
  [ELEMENT_CATEGORIES.SECONDARY]: {
    default: 'bg-black bg-opacity-5',
    white: 'bg-white hover:bg-opacity-100 active:bg-opacity-100',
  },
  [ELEMENT_CATEGORIES.SUCCESS]: {
    default: 'bg-green-300',
  },
  [ELEMENT_CATEGORIES.DANGER]: {
    default: 'bg-red-200',
  },
  [ELEMENT_CATEGORIES.GOLDEN]: {
    default: 'bg-yellow-400',
  },
  [ELEMENT_CATEGORIES.ALERT]: {
    default: 'bg-yellow-100',
  },
};

const theme = {
  [ELEMENT_CATEGORIES.NAKED]: {
    default: 'text-black',
    link: 'text-blue-700',
    hover: 'hover:bg-blue-600 hover:bg-opacity-10',
    active: 'active:bg-blue-600 active:text-blue-700 active:bg-opacity-20',
    disabled: 'bg-none text-opacity-40',
  },
  [ELEMENT_CATEGORIES.OUTLINE]: {
    default: 'border border-gray-700 text-black',
    link: 'border border-blue-700 text-blue-700',
    hover: 'hover:bg-blue-600 hover:bg-opacity-5',
    active: 'active:bg-blue-600 active:text-blue-700 active:bg-opacity-5',
    disabled:
      'text-black border border-black border-opacity-40 bg-gray-100 text-opacity-40',
  },
  [ELEMENT_CATEGORIES.PRIMARY]: {
    default: 'text-white',
    hover: 'hover:bg-blue-800',
    active: 'active:bg-blue-900',
    disabled: 'bg-black bg-opacity-10 text-black text-opacity-40',
  },
  [ELEMENT_CATEGORIES.SECONDARY]: {
    default: 'border border-black border-opacity-5',
    link: 'text-blue-700',
    hover: 'hover:bg-opacity-10 hover:border-opacity-10',
    active: 'active:bg-opacity-30 active:border-opacity-30',
    disabled: 'bg-opacity-10 text-opacity-40',
  },
  [ELEMENT_CATEGORIES.SUCCESS]: {
    default: 'text-green-900',
    hover: 'hover:bg-green-200',
    active: 'active:bg-green-400',
    disabled: 'text-black bg-black text-opacity-40 bg-opacity-5',
  },
  [ELEMENT_CATEGORIES.DANGER]: {
    default: 'text-red-900',
    hover: 'hover:bg-red-300',
    active: 'active:bg-red-400',
    disabled: 'text-black bg-black text-opacity-40 bg-opacity-5',
  },
  [ELEMENT_CATEGORIES.GOLDEN]: {
    default: 'text-black',
    hover: 'hover:bg-yellow-500',
    active: 'active:bg-yellow-600',
    disabled: 'text-black bg-black text-opacity-40 bg-opacity-5',
  },
  [ELEMENT_CATEGORIES.ALERT]: {
    default: 'text-yellow-900',
    link: 'text-blue-700',
    hover: 'hover:bg-yellow-200',
    active: 'hover:bg-yellow-200',
    disabled: '',
  },
};

export { backgrounds };

export default theme;

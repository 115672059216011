module.exports = {
  9: 9,
  12: 12,
  belowModals: 9999,
  max: 10000,
  blueBook_referenceSheet: 10000,
  blueBook_lineReader: 10001,
  blueBook_pauseScreen: 10002,
  overModals: 9999999,
  overPage: 99999999,
};

import React from 'react';
import PropTypes from 'prop-types';
import PopOver from 'chameleon/ui-stack/ui/base/popover';
import ButtonWithIcon from 'chameleon/ui-stack/ui/buttons/button-with-icon';

/** Use HelpMenu as a PureComponent to give access to state*/
class HelpMenu extends React.PureComponent {
  static displayName = 'HelpMenu';

  static propTypes = {
    size: PropTypes.string,
    userData: PropTypes.object,
  };

  static defaultProps = {
    size: 'medium',
    userData: {},
  };

  constructor(props) {
    super(props);
    this._popoverRef = React.createRef();
    this.closePopover = this.closePopover.bind(this);
  }

  closePopover() {
    this._popoverRef.current.close();
  }

  render() {
    const { children, size, userData, pathname } = this.props;
    const { me } = userData;
    /** Pass state popover.close value to the PopOver component  */
    /** Pass HelpMenus closePopover method to the Contact component to update HelpMenus state from the Contact component  */
    const contactOnly = !me;
    const button = (props = {}, isOpen, ref = null) => (
      <ButtonWithIcon
        isIcon
        isSecondary
        accessibility={{ title: 'Help menu' }}
        icon="question_mark"
        data-pin={`help-menu${pathname}`}
        data-tracking-id="help-menu-button"
        className="z-belowModals"
        ref={ref}
        {...props}
      />
    );

    return !userData.isStudent ? (
      button()
    ) : (
      <PopOver
        size={size}
        isNarrow
        withPortal
        className="help_menu"
        contentClassName="relative overflow-hidden p-0"
        accessibility={{
          'aria-label': 'Help menu',
        }}
        trigger={button}
        ref={this._popoverRef}
      >
        <div className="py-6">
          <div className="text-16 pl-6 pb-4 font-medium border-b border-gray-300 w-full">
            Help
          </div>
          <div className="pb-1">
            {!contactOnly &&
              React.Children.map(children, (el) =>
                React.cloneElement(el, { popoverRef: this._popoverRef }),
              )}
          </div>
        </div>
      </PopOver>
    );
  }
}

export default HelpMenu;

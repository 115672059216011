import forEach from 'lodash/forEach';
import omit from 'lodash/omit';
import log from 'chameleon/ui-stack/utilities/log';
import theme, { backgrounds } from './theme';
import { STATES } from './constants';

const getStateThemes = ({
  type,
  background = 'default',
  disabled = false,
  isLink = false,
  isButton = true,
} = {}) => {
  let foundTheme;
  try {
    const rawThemeStates = theme[type];
    let themeStates = {};
    if (disabled) {
      themeStates = {
        [STATES.DISABLED]: rawThemeStates[STATES.DISABLED],
      };
    } else {
      let ignoreStates = ['disabled'];
      if (isLink) {
        ignoreStates.push('default');
      } else {
        ignoreStates.push('link');
      }

      if (!isButton) {
        ignoreStates.push('hover');
        ignoreStates.push('focus');
        ignoreStates.push('active');
      }

      const backgroundTheme =
        backgrounds[type][background] || backgrounds[type].default;

      themeStates = {
        background: backgroundTheme,
      };

      let activeThemeStates = omit(rawThemeStates, ignoreStates);
      forEach(activeThemeStates, (states, rawThemeKey) => {
        themeStates = {
          ...themeStates,
          [rawThemeKey]: states,
        };
      });
    }

    foundTheme = Object.values(themeStates).join(' ');
  } catch (error) {
    foundTheme = '';
    log('error', `Element ${type} theme ${theme} not found`);
    log('error', error);
  }
  return foundTheme;
};

export { getStateThemes };

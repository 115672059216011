export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_FAILURE';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const WIZARDS_FLAGS_INIT = 'WIZARDS_FLAGS_INIT';
export const WIZARDS_DISABLE = 'WIZARDS_DISABLE';
export const WIZARDS_EXAM_CLOSE = 'WIZARDS_EXAM_CLOSE';
export const WIZARDS_EXAM_CLOSE_READY = 'WIZARDS_EXAM_CLOSE_READY';
export const WIZARDS_INFO_FETCH = 'WIZARDS_INFO_FETCH';
export const WIZARDS_INFO_FULLFILLED = 'WIZARDS_INFO_FULLFILLED';

export const SET_ADMIN_SUBJECTS = 'SET_ADMIN_SUBJECTS';
export const SET_ADMIN_INFO = 'SET_ADMIN_INFO';
export const ADMIN_START_LOADING = 'ADMIN_START_LOADING';
export const ADMIN_END_LOADING = 'ADMIN_END_LOADING';

export const SAVE_PERFORMANCE_TIERS = 'SAVE_PERFORMANCE_TIERS';
export const PERFORMANCE_TIERS_START_LOADING =
  'PERFORMANCE_TIERS_START_LOADING';
export const PERFORMANCE_TIERS_END_LOADING = 'PERFORMANCE_TIERS_END_LOADING';

export const QUESTION_PREVIEW_TOGGLE = 'QUESTION_PREVIEW_TOGGLE';
export const QUESTION_ITEM_ID = 'QUESTION_ITEM_ID';

export const NAV_DATA = 'NAV_DATA';
export const NAV_LOADING = 'NAV_LOADING';
export const LOCATION_CHANGE = 'LOCATION_CHANGE';

// subject types
export const SUBJECT_MAPPING = 'SUBJECT_MAPPING';

// student/ section
export const STUDENTS_BY_SECTION = 'STUDENTS_BY_SECTION';

//AP and PRE-AP keys
export const PreAP = 'PRE-AP';
export const AP = 'AP';

export const USER_SUBJECTS = 'USER_SUBJECTS';
export const USER_CLASSES = 'USER_CLASSES';
export const UNIT_FILTERS_DATA = 'UNIT_FILTERS_DATA';
export const UNIT_COURSE_OUTLINE_CONFIG = 'UNIT_COURSE_OUTLINE_CONFIG';

// Feature Flag reducer key
export const SET_FEATURE_FLAG = 'SET_FEATURE_FLAG';

// Question Bank
export const QuestionBank = 'QB';

//Assignment
export const Assignment = 'Assignment';

// Question by shared stimulus data
export const SET_QUESTION_BY_STIMULUS = 'SET_QUESTION_BY_STIMULUS';

export const UPDATE_ASSESSMENT_CACHE = 'UPDATE_ASSESSMENT_CACHE';

//Preferences
export const ATTESTATION_PREFERENCE = 'ACCEPTED_PRACTICE_EXAM_TOC';
export const HIDE_DIGITAL_ASSESSMENT_INFO_MODAL =
  'HIDE_DIGITAL_ASSESSMENT_INFO_MODAL';
export const ATTESTATION_SUBJECT = 'SUBJECT';
export const SAVE_PREFERENCE_SUCCESS = 'SAVE_PREFERENCE_SUCCESS';
export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM';
export const EDUCATIONAL_PERIOD_PREFERENCE = 'EDUCATIONAL_PERIOD_PREFERENCE';
export const PREFERENCE_CONTEXT_SUBJECT = 'SUBJECT';
export const UNIT_FILTER_PREFERENCE = 'UNIT_FILTER_PREFERENCE';
export const JSTOR_TERMS_AND_CONDITIONS = 'JSTOR_TERMS_AND_CONDITIONS';

//Education Perdiod
export const SET_DEFAULT_EDUCATION_PERIOD = 'SET_DEFAULT_EDUCATION_PERIOD';
export const SET_USER_EDUCATION_PERIODS = 'SET_USER_EDUCATION_PERIODS';

//Skill Summary
export const SET_SKILL_SUMMARY_UPDATED_AT = 'SET_SKILL_SUMMARY_UPDATED_AT';

// Shared Resources
export const FETCH_SHARED_RESOURCE_START = 'FETCH_SHARED_RESOURCE_START';
export const FETCH_SHARED_RESOURCE_SUCCESS = 'FETCH_SHARED_RESOURCE_SUCCESS';
export const FETCH_SHARED_RESOURCE_FAILED = 'FETCH_SHARED_RESOURCE_FAILED';
export const UPDATE_SHARED_RESOURCE_STATUS = 'UPDATE_SHARED_RESOURCE_STATUS';

// course outline
export const SET_COURSE_OUTLINE = 'SET_COURSE_OUTLINE';
export const GET_COURSE_OUTLINE = 'GET_COURSE_OUTLINE';
export const RESET_COURSE_OUTLINE = 'RESET_COURSE_OUTLINE';
export const SET_APD_CAROUSEL_DATA = 'SET_APD_CAROUSEL_DATA';
export const UPDATE_APD_CAROUSEL_DATA = 'UPDATE_APD_CAROUSEL_DATA';

// Assessment Types

export const LEARNING_CHECKPOINT = 'is_learning_checkpoint';
export const PERFORMANCE_TASK = 'is_performance_task';

// Resource Filter
export const FETCH_RESOURCE_FILTERS_START = 'FETCH_RESOURCE_FILTERS_START';
export const FETCH_RESOURCE_FILTERS_SUCCESS = 'FETCH_RESOURCE_FILTERS_SUCCESS';
export const FETCH_RESOURCE_FILTERS_FAILED = 'FETCH_RESOURCE_FILTERS_FAILED';

// Search Resources
export const SEARCH_RESOURCES_START = 'SEARCH_RESOURCES_START';
export const SEARCH_RESOURCES_SUCCESS = 'SEARCH_RESOURCES_SUCCESS';
export const SEARCH_RESOURCES_FAILED = 'SEARCH_RESOURCES_FAILED';
export const SEARCH_RESOURCES_RESET = 'SEARCH_RESOURCES_RESET';

// Teacher Assignments
export const FETCH_ASSIGNMENT_DATA_SUCCESS = 'FETCH_ASSIGNMENT_DATA_SUCCESS';
export const FETCH_ASSIGNMENT_DATA_FAILED = 'FETCH_ASSIGNMENT_DATA_FAILED';
export const FETCH_ASSIGNMENT_DATA_START = 'FETCH_ASSIGNMENT_DATA_START';

// Assignment Students Status
export const FETCH_ASSIGNMENT_STUDENTS_STATUS_START =
  'FETCH_ASSIGNMENT_STUDENTS_STATUS_START';
export const FETCH_ASSIGNMENT_STUDENTS_STATUS_SUCCESS =
  'FETCH_ASSIGNMENT_STUDENTS_STATUS_SUCCESS';
export const FETCH_ASSIGNMENT_STUDENTS_STATUS_FAILED =
  'FETCH_ASSIGNMENT_STUDENTS_STATUS_FAILED';
export const REMOVE_ASSIGNMENT_STUDENTS = 'REMOVE_ASSIGNMENT_STUDENTS';

// Bookmarks
export const SET_BOOKMARKS = 'SET_BOOKMARKS';
export const REMOVE_BOOKMARK = 'REMOVE_BOOKMARK';
export const ADD_BOOKMARK = 'ADD_BOOKMARK';

export const SET_SECURE_ASSIGNMENT_IDS = 'SET_SECURE_ASSIGNMENT_IDS';

module.exports = {
  68: '17rem',
  72.6: '18.6rem',
  98: '25rem',
  128: '32rem',
  '1/2': '50%',
  '1/3': '33%',
  600: '600px',
  '75-screen': '75%',
};

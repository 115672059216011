import React from 'react';
import { Link } from 'react-router';
import cn from 'classnames';
import './index.scss';
import A11y from 'chameleon/ui-stack/utilities/a11y';

const FtLinkButton = ({
  label,
  onClick,
  ariaLabel,
  url,
  buttonClassName,
  props,
  containerId = '',
}) => (
  <div id={containerId} className={cn('link_button_wrapper', buttonClassName)}>
    <A11y>
      <Link aria-label={ariaLabel} to={url} onClick={onClick} {...props}>
        {label}
      </Link>
    </A11y>
  </div>
);

export default FtLinkButton;

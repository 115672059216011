const hash = (s) => {
  if (!s) {
    s = '';
  } else if (!s.split) {
    s = JSON.stringify(s);
  }
  return s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

export default hash;

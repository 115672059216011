import React, { useState } from 'react';
import keycode from 'keycode';

const codes = keycode['codes'];

const handleKeyDown = (
  keyEvent,
  currentVal,
  updateFunc,
  previousVal,
  setIsEditing,
  onTextUpdate,
) => {
  const evCode = keyEvent.keyCode;
  if (evCode === codes['esc']) {
    // user pushed escape, so revert back to the old value and close.
    updateFunc(previousVal);
    setIsEditing(false);
  }

  if (evCode === codes['enter'] || evCode === codes['tab']) {
    // user pushed enter or tab, persist the value
    handleBlur(currentVal, setIsEditing, onTextUpdate);
  }
};

const handleNoEditKeyDown = (
  keyEvent,
  currentVal,
  setPreviousVal,
  setIsEditing,
) => {
  const evCode = keyEvent.keyCode;
  if (evCode === codes['enter'] || evCode === codes['space']) {
    handleOnClick(currentVal, setPreviousVal, setIsEditing);
  }
};

const handleBlur = (currentVal, setIsEditing, onTextUpdate) => {
  onTextUpdate(currentVal);
  setIsEditing(false);
};

const handleOnClick = (currentVal, setPreviousVal, setIsEditing) => {
  setPreviousVal(currentVal);
  setIsEditing(true);
};

/**
 * A text display which allows the user to click on it and cause it to switch to editable. Then, when clicking off the
 * text box, or pushing enter, the text is saved and a function from the parent component is called to persist the value
 * (however you want to do that). If the user pushes escape, then the value change id discarded and the previous
 * value is returned.
 * @param startingText - The initial text that will be displayed for the item
 * @param onTextUpdate - The function that should run when the text is successfully updated. Accepts one parameter, `newText`
 * @returns {JSX.Element}
 * @constructor
 */
const InlineEditTextInput = ({ startingText, onTextUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentVal, updateCurrentVal] = useState(startingText);
  const [previousVal, updatePreviousVal] = useState(startingText);
  // Sometimes the text gets updated after the initial render of a component using this component. Instead of stubbornly
  // sticking with whatever value we were given first, we keep track of whether or not the user has started to edit
  // the text. If not, then we'll just use the latest thing the user gave us.
  const [userEdited, setUserEdited] = useState(false);

  if (!userEdited && startingText !== currentVal) {
    updateCurrentVal(startingText);
  }

  // This component ignores the eslint warning about no-autofocus because the autofocus in question here
  // happens immediately after a click event by the user. In this case, the worse UX is not auto-focusing.
  // ES lint can't tell the difference, so we disable the check for that line.
  return isEditing ? (
    <input
      className="inline-editable-text-input-box"
      /* eslint-disable jsx-a11y/no-autofocus */
      autoFocus
      type="text"
      value={currentVal}
      onKeyDown={(e) => {
        e.stopPropagation();
        handleKeyDown(
          e,
          currentVal,
          updateCurrentVal,
          previousVal,
          setIsEditing,
          onTextUpdate,
        );
      }}
      onBlur={() => handleBlur(currentVal, setIsEditing, onTextUpdate)}
      onChange={(event) => updateCurrentVal(event.target.value)}
    />
  ) : (
    <div
      className="inline-editable-text-input-div"
      onClick={() => {
        setUserEdited(true);
        handleOnClick(currentVal, updatePreviousVal, setIsEditing);
      }}
      role="button"
      tabIndex="0"
      onKeyDown={(e) => {
        e.stopPropagation();
        handleNoEditKeyDown(e, currentVal, updatePreviousVal, setIsEditing);
      }}
    >
      {currentVal}
    </div>
  );
};

export default InlineEditTextInput;

import React, { Fragment } from 'react';

export default {
  stopwatch: {
    viewBox: '0 0 20 24',
    body: () => (
      <Fragment>
        <defs key="1">
          <path
            id="stopwatch-a"
            d="M239.2 111.6l1-1c.3-.3.3-.6 0-.8l-.8-.8a.6.6 0 0 0-.8 0l-1 1a10 10 0 0 0-5.4-2.4v-2.3h1.3c.3 0 .6-.3.6-.6v-1.1c0-.3-.3-.6-.6-.6h-5c-.3 0-.6.3-.6.6v1c0 .4.3.7.6.7h1.3v2.3c-5 .5-8.8 4.6-8.8 9.7 0 5.3 4.5 9.7 10 9.7s10-4.4 10-9.8c0-2-.7-4-1.8-5.6zm-8.2 13.2a7.6 7.6 0 0 1-7.7-7.5c0-4.2 3.4-7.5 7.7-7.5 4.3 0 7.7 3.3 7.7 7.5 0 4-3.4 7.5-7.7 7.5zm.6-5.3h-1.2a.6.6 0 0 1-.6-.6v-6.3c0-.3.3-.6.6-.6h1.2c.3 0 .6.3.6.6v6.3c0 .3-.3.6-.6.6z"
          />
        </defs>
        ,
        <use
          key="2"
          transform="translate(-221 -103)"
          xlinkHref="#stopwatch-a"
        />
      </Fragment>
    ),
  },

  youtube: {
    viewBox: '0 0 48 48',
    body: () => (
      <Fragment>
        <g clipPath="url(#clip0)">
          <path
            d="M47.044 12.3709C46.7726 11.3498 46.2378 10.4178 45.493 9.66825C44.7483 8.91872 43.8197 8.37794 42.8003 8.10003C39.0476 7.09094 24.0476 7.09094 24.0476 7.09094C24.0476 7.09094 9.04761 7.09094 5.29488 8.10003C4.27547 8.37794 3.34693 8.91872 2.60218 9.66825C1.85744 10.4178 1.32262 11.3498 1.05124 12.3709C0.0476075 16.14 0.0476074 24 0.0476074 24C0.0476074 24 0.0476075 31.86 1.05124 35.6291C1.32262 36.6503 1.85744 37.5823 2.60218 38.3318C3.34693 39.0813 4.27547 39.6221 5.29488 39.9C9.04761 40.9091 24.0476 40.9091 24.0476 40.9091C24.0476 40.9091 39.0476 40.9091 42.8003 39.9C43.8197 39.6221 44.7483 39.0813 45.493 38.3318C46.2378 37.5823 46.7726 36.6503 47.044 35.6291C48.0476 31.86 48.0476 24 48.0476 24C48.0476 24 48.0476 16.14 47.044 12.3709Z"
            fill="#0077C8"
          />
          <path
            d="M19.1387 31.1373V16.8628L31.6841 24.0001L19.1387 31.1373Z"
            fill="#FEFEFE"
          />
        </g>

        <defs>
          <clipPath id="clip0">
            <rect width="48" height="48" fill="white" />
          </clipPath>
        </defs>
      </Fragment>
    ),
  },

  ap_daily: {
    description: 'AP Daily',
    viewBox: '0 0 18 16',
    body: () => (
      <Fragment>
        <defs>
          <path id="og3ye08v2a" d="M0 0L17 0 17 16 0 16z" />
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(.56)">
          <mask id="cfzj7stm7b" fill="#fff">
            <use xlinkHref="#og3ye08v2a" />
          </mask>
          <path
            fill="#0075C8"
            d="M15.896 16H1.104C.494 16 0 15.506 0 14.896V1.104C0 .494.495 0 1.104 0h14.792C16.506 0 17 .494 17 1.104v13.792c0 .61-.494 1.104-1.104 1.104"
            mask="url(#cfzj7stm7b)"
          />
          <path
            fill="#FFF"
            d="M11.56 8.175l-5.096 3.412c-.147.099-.349-.003-.349-.177V4.59c0-.173.202-.275.35-.177L11.56 7.82c.128.085.128.269 0 .354"
          />
        </g>
      </Fragment>
    ),
  },
};

module.exports = {
  0.2: '0.2rem',
  0.3: '0.3rem',
  0.4: '0.4rem',
  0.5: '0.5rem',
  0.6: '0.6rem',
  0.8: '0.8rem',
  8.6: '2.6rem',
  8.9: '2.9rem',
  20: '5rem',
  24.8: '6.8rem',
};

import React, { PureComponent, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import cn from 'classnames';
import omit from 'lodash/omit';
import Button from 'chameleon/ui-stack/ui/base/button';

import './styles.scss';

/**
 * Wrapper for accessibility components
 * It wraps a component with onKeyPress event.
 * The used keys are ENTER and SPACE.
 * */
class AccessibilityWrapper extends PureComponent {
  static displayName = 'AccessibilityWrapper';

  static propTypes = {
    disableAutoFocus: PropTypes.bool,
    enableOnClick: PropTypes.bool,
  };

  static defaultProps = {
    disableAutoFocus: false,
    enableOnClick: false,
  };

  constructor(props) {
    super(props);

    this.element = props.children.ref || React.createRef();
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  /**
   * The event handler for ENTER/SPACE key press on the element
   * @param {Object} event - The event triggered.
   * */
  onKeyPress(event) {
    const { children, childrenRef } = this.props;
    const { which: keyCode } = event;

    if (children) {
      if ([13, 32].includes(keyCode)) {
        event.preventDefault();
        event.stopPropagation();

        const { type, props } = children;

        // Check what kind of children is given and act as default.
        if (childrenRef && childrenRef.current) {
          childrenRef.current.click && childrenRef.current.click();
        } else if (
          type === 'a' ||
          type === 'button' ||
          type.displayName === Link.displayName ||
          type.displayName === Button.displayName
        ) {
          this.element.current &&
            this.element.current.click &&
            this.element.current.click();
        } else {
          const { onClick } = props;
          onClick && onClick(event);
        }
      }
    }
  }

  onFocus() {
    const { children } = this.props;
    if (children) {
      const { current } = this.element;
      current?.focus?.();
    }
  }

  render() {
    const { children, disableAutoFocus, enableOnClick, onClick } = this.props;

    const childrenProps = {
      ...omit(children.props, ['className']),
      ref: this.element,
      className: cn('AccessibilityWrapper', children.props.className),
      onKeyPress: this.onKeyPress,
    };
    if (!disableAutoFocus) {
      childrenProps.onFocus = this.onFocus;
    }
    if (enableOnClick && !childrenProps.onClick) {
      childrenProps.onClick = onClick;
    }

    if (children) {
      return cloneElement(Children.only(children), childrenProps);
    }

    return null;
  }
}

export default AccessibilityWrapper;

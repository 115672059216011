module.exports = {
  0.3: '0.3rem',
  0.4: '0.4rem',
  0.5: '0.5rem',
  0.6: '0.6rem',
  0.8: '0.8rem',
  4.1: '1.1rem',
  4.2: '1.2rem',
  4.4: '1.4rem',
  4.5: '1.5rem',
  4.6: '1.6rem',
  4.7: '1.7rem',
  4.8: '1.8rem',
  8.2: '2.2rem',
  8.3: '2.3rem',
  8.4: '2.4rem',
  8.5: '2.5rem',
  8.7: '2.7rem',
  8.8: '2.8rem',
  12.2: '3.2rem',
  12.8: '3.8rem',
  18: '4.5rem',
  16.8: '4.8rem',
  20.2: '5.2rem',
  25: '6.4rem',
  28.2: '7.2rem',
  98: '25rem',
};

import axios from 'axios';
import log from 'chameleon/ui-stack/utilities/log';

/**
 * Fire Request
 * Make an axios call with the correct headers.
 *
 * @param  method {String}  Restful method.
 * @param  url    {String}  API URL endpoint.
 * @param  data   {Object}  Any data to send along.
 *                          Either axios.options.data or axios.options.params.
 * @param  headers {Object}  Any headers to send along.
 * @return        {Promise} The data that the server has returned.
 */
const _fireRequest = (method = 'get', url = '', data = {}, headers = {}) => {
  const params = method === 'get' ? data : {};

  const options = {
    data,
    params,
    method,
    headers,
    url: url.includes('https://') ? url : `${FYM_BACKEND_URL}/${url}`,
  };

  return axios(options).then((response) => response.data);
};

export default {
  get: (...args) => _fireRequest('get', ...args),
  post: (...args) => _fireRequest('post', ...args),
  put: (...args) => _fireRequest('put', ...args),
  delete: (...args) => _fireRequest('delete', ...args),
  all: (calls) =>
    axios
      .all(
        calls.map(({ method, url, data, headers }) =>
          _fireRequest(method, url, data, headers),
        ),
      )
      .catch((error) => log('error', error)),
};

let signedURLCache = {};

/* Sign the url only if it points to the media distribution  */
export const getSignedUrl = (data, failIfKeyIsMissing = false) => {
  const cacheKey = JSON.stringify(data);
  if (signedURLCache[cacheKey]) {
    return Promise.resolve(signedURLCache[cacheKey]);
  }
  const isString = typeof data === 'string';
  if (isString) {
    const url = data;
    if (
      /^(https:)?\/\/media(\.(dev|testing|staging))?\.academicmerit\.com/.test(
        url,
      )
    ) {
      return _fireRequest('post', 'media/api/signed_url', {
        url,
        fail_if_key_is_missing: failIfKeyIsMissing,
      }).then((result) => {
        let signedURL = result.signedUrl;
        signedURLCache[cacheKey] = signedURL;
        return signedURL;
      });
    }
    return Promise.resolve(url);
  }
  return _fireRequest('post', 'media/api/signed_url', {
    ...data,
    fail_if_key_is_missing: failIfKeyIsMissing,
  }).then((result) => {
    let signedURL = result.signedUrl;
    signedURLCache[cacheKey] = signedURL;
    return signedURL;
  });
};

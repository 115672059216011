import { has, get, size, reduce, isEmpty } from 'lodash';
import { addQuery } from 'legacy-src/utility/buildUrl';

export const ACTIONS = {
  handleActiveDistrict: 'HANDLE_ACTIVE_DISTRICT',
  handleActiveSchool: 'HANDLE_ACTIVE_SCHOOL',
  handleActiveSection: 'HANDLE_ACTIVE_SECTION',
  handleActiveStudent: 'HANDLE_ACTIVE_STUDENT',
  handleActiveEducationPeriod: 'HANDLE_ACTIVE_EDUCATION_PERIOD',
  handleActiveUserStatus: 'HANDLE_ACTIVE_USER_STATUS',
  setAllEducationPeriods: 'SET_ALL_EDUCATION_PERIODS',
  setHierarchy: 'SET_HIERARCHY',
  setDistricts: 'SET_DISTRICTS',
  setSchools: 'SET_SCHOOLS',
  setSections: 'SET_SECTIONS',
  setStudents: 'SET_STUDENTS',
  handleSetupInitialState: 'HANDLE_SET_UP_INITIAL_STATE',
  updateCachedFilterProps: 'UPDATE_CACHED_FILTER_PROPS',
  toggleCachedFilterProps: 'TOGGLE_CACHED_FILTER_PROPS',
  storeFlattenedGroups: 'STORE_FLATTENED_GROUPS',
};

export const TYPES = {
  district: 'DISTRICT_TYPE',
  school: 'SCHOOL_TYPE',
  section: 'SECTION_TYPE',
  status: 'STATUS_TYPE',
  student: 'STUDENT_TYPE',
  educationPeriod: 'EDUCATION_PERIOD_TYPE',
  skillCategory: 'SKILL_CATEGORY_TYPE',
  allSkillCategories: 'ALL_SKILL_CATEGORIES',
  none: 'NONE_TYPE',
  mcq: 'mcq',
  frq: 'frq',
  asc: 'asc',
  des: 'des',
  localStorageGroupFilterKey: 'activeGroupFilters',
};

// constant value for all schools selection
export const ALL_SCHOOLS = {
  label: 'All Schools',
  value: -1,
  type: TYPES.school,
};

// constant value for all classes selection
export const ALL_SECTIONS = {
  label: 'All Sections',
  value: -2,
  type: TYPES.section,
};

// constant value for all students  selection
export const ALL_STUDENTS = {
  label: 'All Students',
  value: -3,
  type: TYPES.student,
};

// constant value for all districts selection
export const ALL_DISTRICTS = {
  label: 'All Districts',
  value: -6,
  type: TYPES.district,
};

// constant value for default district value
export const DEFAULT_DISTRICT = {
  label: '',
  value: -4,
  type: TYPES.district,
};

// constant value for default district value
export const DEFAULT_SCHOOL = {
  label: '',
  value: -7,
  type: TYPES.school,
};

// constant value for default student value
export const DEFAULT_STUDENT = {
  label: '',
  value: -5,
  type: TYPES.student,
};

// constant value for default userStatus value
export const DEFAULT_STATUS = {
  label: '',
  value: 'all',
  type: TYPES.status,
};

// default unit selection for the student dashboard units filter
export const DEFAULT_UNIT = {
  label: 'All Units',
  value: -1,
};

// constant values that handle the "none selected" state and the "all selected" state
export const INITIAL_DISTRICTS = {
  defaultValue: ALL_DISTRICTS,
  values: [ALL_DISTRICTS],
};

// constant values that handle the "none selected" state and the "all selected" state
export const INITIAL_SCHOOLS = {
  defaultValue: ALL_SCHOOLS,
  values: [ALL_SCHOOLS],
};

// constant values that handle the "none selected" state and the "all selected" state
export const INITIAL_SECTIONS = {
  defaultValue: ALL_SECTIONS,
  values: [ALL_SECTIONS, ALL_STUDENTS],
};

// constant values that handle the "none selected" state and the "all selected" state
export const INITIAL_STATUS = {
  defaultValue: DEFAULT_STATUS,
  values: [
    {
      label: 'Current Students',
      value: 'current',
      type: TYPES.status,
    },
    {
      label: 'Previous Students',
      value: 'prior',
      type: TYPES.status,
    },
  ],
};

// constant values that handle the "none selected" state and the "all selected" state
export const INITIAL_STUDENTS = {
  defaultValue: DEFAULT_STUDENT,
  values: [],
};

export const defaultValues = {
  districts: INITIAL_DISTRICTS.values,
  schools: INITIAL_SCHOOLS.values,
  sections: INITIAL_SECTIONS.values,
  students: INITIAL_STUDENTS.values,
  allSchools: ALL_SCHOOLS,
  allSections: ALL_SECTIONS,
  allStudents: ALL_STUDENTS,
  district: INITIAL_DISTRICTS.defaultValue,
  school: INITIAL_SCHOOLS.defaultValue,
  section: INITIAL_SECTIONS.defaultValue,
  status: INITIAL_STATUS.defaultValue,
  student: INITIAL_STUDENTS.defaultValue,
  userStatus: DEFAULT_STATUS,
};

export const setData = ({ data = {}, initialState, type, section }) => {
  const isStudent = type === TYPES.student;
  const sortedData = isStudent
    ? Object.keys(data)
        .sort((first, second) => {
          const firstStudentName = `${data[
            first
          ].last_name.toUpperCase()}${data[first].first_name.toUpperCase()}`;
          const secondStudentName = `${data[
            second
          ].last_name.toUpperCase()}${data[second].first_name.toUpperCase()}`;
          if (firstStudentName > secondStudentName) {
            return 1;
          }
          if (firstStudentName < secondStudentName) {
            return -1;
          }
          return 0;
        })
        .map((datum) => ({
          label: `${data[datum].first_name} ${data[datum].last_name}`,
          value: section ? `${section.id}-${data[datum].id}` : data[datum].id,
          type,
          firstName: data[datum].first_name,
          lastName: data[datum].last_name,
          id: section ? `${section.id}-${data[datum].id}` : data[datum].id,
        }))
    : // sort by name alphabetically
      Object.keys(data)
        .sort((first, second) => {
          if (
            data[first].name.toUpperCase() > data[second].name.toUpperCase()
          ) {
            return 1;
          }
          if (
            data[first].name.toUpperCase() < data[second].name.toUpperCase()
          ) {
            return -1;
          }
          return 0;
        })
        .map((datum) => ({
          label: data[datum].name,
          value: data[datum].id,
          id: data[datum].id,
          type,
        }));

  // data set includes initial state properties (default values) and sorted data coming from api call
  return {
    sortedData,
    aggregatedList:
      sortedData.length > 1 ? [...initialState, ...sortedData] : sortedData,
  };
};

export const filterHierarchyData = (data) =>
  reduce(
    data,
    (districtAccum, district) => {
      const filteredSchools = reduce(
        district.schools,
        (schoolAccum, school) => ({
          ...schoolAccum,
          ...(!isEmpty(school.sections) ? { [school.id]: school } : null),
        }),
        {},
      );
      return {
        ...districtAccum,
        ...(!isEmpty(filteredSchools)
          ? {
              [district.id]: {
                ...district,
                schools: filteredSchools,
              },
            }
          : null),
      };
    },
    {},
  );

export const formatUserEducationPeriods = (
  educationPeriods,
  earliestReportingEP,
) =>
  educationPeriods
    .filter((period) => period.id >= earliestReportingEP)
    .map((period) => ({
      label: period.displayValue,
      value: period.id,
      type: TYPES.educationPeriod,
    }));

export const formatCurrentEducationPeriod = (currentEducationPeriod) => ({
  defaultValue: {
    label: currentEducationPeriod.displayValue,
    value: currentEducationPeriod.id,
    type: TYPES.educationPeriod,
  },
  values: [],
});

const handleDataList = ({ dataList, selection, type }) =>
  isEmpty(selection) && type === TYPES.student
    ? null
    : !isEmpty(selection)
      ? selection
      : size(dataList.sortedData) === 1
        ? get(dataList, ['sortedData', 0], null)
        : get(dataList, ['aggregatedList', 0], null);

// Function to set initial state.
export const setInitialGroupFilterState = ({
  dispatch,
  activeDistrictId,
  activeSchoolId,
  activeSectionId,
  activeUserStatusValue,
  activeStudentId,
  activeEducationPeriodValue,
  userEducationPeriods,
  filteredData,
  initialState,
  hasInitialStateFromStorageOrQuery,
  showEmptySelection = false,
  hideAllDistricts = false,
}) => {
  // create list of districts
  const districts = setData({
    data: filteredData,
    // remove ALL Districts if there is only one district. otherwise, just leave all initial options be
    initialState: initialState.districts.filter(
      (district) =>
        (Object.keys(filteredData).length <= 1 &&
          district.value !== ALL_DISTRICTS.value) ||
        (Object.keys(filteredData).length > 1 &&
          hideAllDistricts &&
          district.value !== ALL_DISTRICTS.value) ||
        (Object.keys(filteredData).length > 1 && !hideAllDistricts),
    ),
    type: TYPES.district,
  });

  // grab active district based off initial state (or default to a fallback in handleDataList)
  const matchingDistrict =
    // if ALL DISTRICTS is an option
    // and no initial state from storage,
    // we will provide a blank selection on initial state setup
    districts.aggregatedList.some(
      (district) => district.value === ALL_DISTRICTS.value,
    ) &&
    !hasInitialStateFromStorageOrQuery &&
    showEmptySelection
      ? DEFAULT_DISTRICT
      : // if we have ALL DISTRICTS option and the district value is DEFAULT_DISTRICT or ALL_DISTRICTS value,
        districts.aggregatedList.some(
            (district) => district.value === ALL_DISTRICTS.value,
          ) &&
          (activeDistrictId === DEFAULT_DISTRICT.value ||
            activeDistrictId === ALL_DISTRICTS.value)
        ? // we want to just auto select the first district
          districts.sortedData[0]
        : // otherwise just do the normal parsing
          handleDataList({
            dataList: districts,
            selection: get(districts, 'aggregatedList', []).find(
              (district) =>
                parseInt(activeDistrictId, 10) === parseInt(district.value, 10),
            ),
            type: TYPES.district,
          });

  // grab active district value
  const matchingDistrictValue =
    get(matchingDistrict, 'value') || get(matchingDistrict, 'id');

  // create list of schools
  const schools =
    matchingDistrictValue === ALL_DISTRICTS.value ||
    matchingDistrictValue === DEFAULT_DISTRICT.value
      ? setData({
          data: Object.values(filteredData).reduce(
            (accum, district) =>
              has(district, 'schools')
                ? { ...accum, ...district.schools }
                : accum,
            {},
          ),
          initialState: initialState.schools,
          type: TYPES.school,
        })
      : matchingDistrict
        ? setData({
            data: get(filteredData, [matchingDistrictValue, 'schools'], {}),
            initialState: initialState.schools,
            type: TYPES.school,
          })
        : initialState.schools;

  // grab active school based off initial state (or default to a fallback in handleDataList)
  const matchingSchool =
    matchingDistrictValue === DEFAULT_DISTRICT.value
      ? DEFAULT_SCHOOL
      : handleDataList({
          dataList: schools,
          selection: get(schools, 'aggregatedList', []).find(
            (school) =>
              parseInt(activeSchoolId, 10) === parseInt(school.value, 10),
          ),
          type: TYPES.school,
        });

  // grab active school value
  const matchingSchoolValue =
    get(matchingSchool, 'value') || get(matchingSchool, 'id');

  // create list of sections
  const getSections = Object.values(
    get(filteredData, [matchingDistrictValue, 'schools'], {}),
  ).reduce((acc, school) => ({ ...acc, ...school.sections }), {});
  const sections = setData({
    data: Object.values(getSections),
    initialState: initialState.sections,
    type: TYPES.section,
  });

  // grab active section based off initial state (or default to a fallback in handleDataList)
  const matchingSection = handleDataList({
    dataList: sections,
    selection: get(sections, 'aggregatedList', []).find(
      (section) =>
        parseInt(activeSectionId, 10) === parseInt(section.value, 10),
    ),
    type: TYPES.section,
  });

  // grab active section value
  const matchingSectionValue =
    get(matchingSection, 'value') || get(matchingSection, 'id');

  // search through all sections if ALL STUDENTS or ALL SECTIONS is selected
  const searchAllSections =
    matchingDistrictValue &&
    matchingSchoolValue &&
    (parseInt(activeSectionId, 10) === ALL_STUDENTS.value ||
      parseInt(activeSectionId, 10) === ALL_SECTIONS.value);

  // create list of students
  const students = searchAllSections
    ? setData({
        // aggregate all the students into a single list if ALL STUDENTS or ALL SECTIONS is selected
        data: Object.values(
          get(
            filteredData,
            [matchingDistrictValue, 'schools', matchingSchoolValue, 'sections'],
            {},
          ),
        ).reduce((accum, section) => {
          // append the section_id to the student_id
          const studentsWithSectionId =
            has(section, 'students') &&
            Object.values(section.students).reduce(
              (studentsAccum, student) => ({
                ...studentsAccum,
                [student.id]: {
                  ...student,
                  id: `${section.id}-${student.id}`,
                },
              }),
              {},
            );
          return has(section, 'students') && !isEmpty(section.students)
            ? { ...accum, ...studentsWithSectionId }
            : accum;
        }, {}),
        initialState: initialState.students,
        type: TYPES.student,
      })
    : // if not searching through all sections, create list of students based off hierarchy
      matchingSection
      ? setData({
          data: get(
            filteredData,
            [
              matchingDistrictValue,
              'schools',
              matchingSchoolValue,
              'sections',
              matchingSectionValue,
              'students',
            ],
            {},
          ),
          initialState: initialState.students,
          type: TYPES.student,
          section: matchingSection,
        })
      : initialState.students;

  // studentId can be in the form of `${sectionId}-${studentId}` or `${studentId}`. this should handle both those cases
  const studentId =
    activeStudentId && get(`${activeStudentId}`.split('-'), 1, activeStudentId);
  // grab active student based off initial state (or default to a fallback in handleDataList)
  const matchingStudent = handleDataList({
    dataList: students,
    selection: get(students, 'aggregatedList', []).find(
      (student) =>
        parseInt(studentId, 10) ===
        parseInt(student.value && get(`${student.value}`.split('-'), 1), 10),
    ),
    type: TYPES.student,
  });

  const matchingEducationPeriod = userEducationPeriods
    ? userEducationPeriods.find(
        (value) =>
          parseInt(value.id, 10) === parseInt(activeEducationPeriodValue, 10),
      )
    : [];
  const matchingUserStatus = INITIAL_STATUS.values.find(
    (status) => status.value === activeUserStatusValue,
  );

  // set the initial hierarchy state
  dispatch({
    type: ACTIONS.handleSetupInitialState,
    payload: {
      hierarchy: filteredData,
      ...(matchingDistrict && {
        activeDistrict: {
          label: matchingDistrict.name,
          value: matchingDistrict.id,
          ...matchingDistrict,
        },
      }),
      districts: districts.aggregatedList,
      ...(matchingSchool && {
        activeSchool: {
          label: matchingSchool.name,
          value: matchingSchool.id,
          ...matchingSchool,
        },
      }),
      schools: schools.aggregatedList,
      ...(matchingSection && {
        activeSection: {
          label: matchingSection.name,
          value: matchingSection.id,
          ...matchingSection,
        },
      }),
      sections: sections.aggregatedList,
      ...(matchingStudent && {
        activeStudent: {
          label:
            matchingStudent.name ||
            `${matchingStudent.first_name} ${matchingStudent.last_name}`,
          value: matchingStudent.id,
          id: matchingStudent.value,
          ...matchingStudent,
        },
      }),
      students: students.aggregatedList,
      ...(matchingEducationPeriod && {
        activeEducationPeriod: {
          label: matchingEducationPeriod.displayValue,
          value: matchingEducationPeriod.id,
          ...matchingEducationPeriod,
        },
      }),
      ...(matchingUserStatus && {
        activeUserStatus: {
          label: matchingUserStatus.label,
          value: matchingUserStatus.value,
          ...matchingUserStatus,
        },
      }),
    },
  });
};

const storeActiveId = ({ key, value, location, router }) => {
  const groupFilters = {
    ...JSON.parse(
      window.localStorage.getItem(TYPES.localStorageGroupFilterKey) || '{}',
    ),
    [key]: value,
  };
  window.localStorage.setItem(
    TYPES.localStorageGroupFilterKey,
    JSON.stringify(groupFilters),
  );
  router.push({
    pathname: location.pathname,
    query: addQuery(location.query, groupFilters),
  });
};

export const applyMiddleware =
  ({ dispatch, state, location, router }) =>
  (action) =>
    (
      ({
        [ACTIONS.handleSetupInitialState]: () => {
          const {
            activeDistrict,
            activeSchool,
            activeSection,
            activeStudent,
            activeUserStatus,
            activeEducationPeriod,
          } = action.payload;
          const groupFilters = {
            districtId: activeDistrict
              ? activeDistrict.value
              : defaultValues.district.value,
            schoolId: activeSchool
              ? activeSchool.value
              : defaultValues.school.value,
            sectionId: activeSection
              ? activeSection.value
              : defaultValues.section.value,
            studentId: activeStudent
              ? activeStudent.value
              : defaultValues.student.value,
            educationPeriod: activeEducationPeriod
              ? activeEducationPeriod.value
              : state.activeEducationPeriod.value,
            userStatus: activeUserStatus
              ? activeUserStatus.value
              : defaultValues.userStatus.value,
          };
          window.localStorage.setItem(
            TYPES.localStorageGroupFilterKey,
            JSON.stringify(groupFilters),
          );
          router.push({
            pathname: location.pathname,
            query: addQuery(location.query, groupFilters),
          });
          dispatch(action);
        },
        [ACTIONS.handleActiveDistrict]: () => {
          const prevState = { ...state };
          if (
            action.payload.value === ALL_DISTRICTS.value &&
            !prevState.showCachedFilterProps
          ) {
            dispatch({
              type: ACTIONS.updateCachedFilterProps,
              payload: prevState,
            });
          } else if (action.payload.value !== ALL_DISTRICTS.value) {
            // do not store the "ALL DISTRICTS" selection, can be moved outside of if condition when we have proper handling from backend
            storeActiveId({
              key: 'districtId',
              value: action.payload.value,
              location,
              state,
              router,
            });
          }
          dispatch({
            type: ACTIONS.toggleCachedFilterProps,
            payload: action.payload.value === ALL_DISTRICTS.value,
          });
          dispatch(action);
        },
        [ACTIONS.handleActiveSchool]: () => {
          storeActiveId({
            key: 'schoolId',
            value: action.payload.value,
            location,
            state,
            router,
          });
          // if the active district selection is ALL DISTRICTS or the DEFAULT DISTRICT (blank)
          // AND the school selection is an actual school, we fill in the active district selection
          if (
            (state.activeDistrict.value === ALL_DISTRICTS.value ||
              state.activeDistrict.value === DEFAULT_DISTRICT.value) &&
            action.payload.value > 0
          ) {
            const districtSelection = state.districts.find(
              (district) =>
                district.id ===
                get(
                  state,
                  ['flattenedGroups', action.payload.value, 'parentId'],
                  0,
                ),
            );
            dispatch({
              type: ACTIONS.handleActiveDistrict,
              payload: districtSelection,
            });
            // update cache to reflect this selection and the 'backfill' of district selection
            dispatch({
              type: ACTIONS.updateCachedFilterProps,
              payload: {
                ...state,
                activeDistrict: districtSelection,
                activeSchool: action.payload,
              },
            });
            dispatch({
              type: ACTIONS.toggleCachedFilterProps,
              payload: districtSelection.value === ALL_DISTRICTS.value,
            });
            storeActiveId({
              key: 'districtId',
              value: districtSelection.value,
              location,
              state,
              router,
            });
          }
          dispatch(action);
        },
        [ACTIONS.handleActiveSection]: () => {
          storeActiveId({
            key: 'sectionId',
            value: action.payload.value,
            location,
            state,
            router,
          });
          dispatch(action);
        },
        [ACTIONS.handleActiveStudent]: () => {
          storeActiveId({
            key: 'studentId',
            value: action.payload.value,
            location,
            state,
            router,
          });
          dispatch(action);
        },
        [ACTIONS.handleActiveEducationPeriod]: () => {
          storeActiveId({
            key: 'educationPeriod',
            value: action.payload.value,
            location,
            state,
            router,
          });
          dispatch(action);
        },
        [ACTIONS.handleActiveUserStatus]: () => {
          storeActiveId({
            key: 'userStatus',
            value: action.payload.value,
            location,
            state,
            router,
          });
          dispatch(action);
        },
      })[action.type] || dispatch
    )(action);

export const prefix_object = (prefix, old_obj) =>
  Object.keys(old_obj).reduce((obj, key) => {
    obj[prefix + key] = old_obj[key];
    return obj;
  }, {});

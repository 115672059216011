export function getArrowPosition({ placement, rects, x, y, middlewareData }) {
  // Position the pointing arrow
  let arrowX = x;
  let arrowY = y;

  if (placement.includes('start')) {
    arrowX += rects.reference.width / 2 + Math.abs(middlewareData.offset.x);
  }

  if (placement.includes('center') || ['top', 'bottom'].includes(placement)) {
    arrowX += rects.floating.width / 2;
  }

  if (placement.includes('end')) {
    arrowX +=
      rects.floating.width -
      rects.reference.width / 2 -
      middlewareData.shift.x -
      Math.abs(middlewareData.offset.x);
  }

  if (placement.includes('top')) {
    arrowY += rects.floating.height - 1;
  }

  if (placement.includes('bottom')) {
    arrowY -= 11;
  }

  return { x: arrowX, y: arrowY };
}

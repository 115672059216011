import React, { PureComponent } from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { Icon, hash, LoadingSpinner } from '../../index';
import './index.scss';
import Button from 'chameleon/ui-stack/ui/base/button';
import OnDemandLiveRegion from 'on-demand-live-region';

/*
toast("Hello World") // A default notification
toast.error("Oh noes!") or toast("Oh noes!", {type: 'error'}) // With a type of "error"
toast("Self destructing!", {timeout: 5}) // Disappears after 5 seconds
toast.confirm('Delete?', deleteConfirmationMessage , 'confirm-error', {
      onConfirm: this.onDeleteAssessmentConfirm,
      onClose: this.onCloseDeleteAssessmentConfirm
    }); //confirm-error for red, confirm-warning for orange
//Call to Action confirmation
toast.cta('This is a timed assessment!', ctaConfirmationMessage, {
 onConfirm: this.onCtaConfirm,
 onClose: this.onCtaClose
}
*/

//TODO: Implement CTA and Confirm modes. Or modes that make sense for a toast message.

const notifications = [];
const instances = [];

const toast = (message, options = {}) => {
  const config = _.isObject(message) && message.message ? message : { message };
  _.defaults(config, options, {
    icon: '',
    type: 'notification',
  });
  config.key = config.key || hash(config);
  notifications.push(config);
  render();
  if (config.timeout) {
    setTimeout(() => remove(config.key), config.timeout * 1000);
  }
  return () => remove(config.key);
};

toast.error = (message, options = {}) => {
  toast(
    message,
    _.assign(
      {
        type: 'error',
        icon: 'circle_warn',
      },
      options,
    ),
  );
};

toast.confirm = (title, message, confirmType, options = {}) => {
  toast(
    message,
    _.assign(
      {
        title,
        confirmType,
        type: 'confirm',
        icon: 'circle_warn',
      },
      options,
    ),
  );
};

toast.cta = (title, message, options = {}) => {
  toast(
    message,
    _.assign(
      {
        title,
        type: 'cta',
      },
      options,
    ),
  );
};

toast.progress = () => (loading, success, error) => {
  let removeProgress;
  if (loading !== null) {
    removeProgress = toast(<LoadingSpinner message={loading} key={loading} />);
  }
  return [
    (value) => {
      if (loading !== null) removeProgress();
      if (success !== null) {
        toast({
          message: success || 'Complete!',
          timeout: 2,
        });
      }
      return value;
    },
    (reason) => {
      if (loading !== null) removeProgress();
      if (error !== null) {
        toast({
          type: 'error',
          icon: 'circle_warn',
          message: error || reason,
          timeout: 10,
        });
        throw reason;
      }
    },
  ];
};

const remove = (key) => {
  const index = _.findIndex(notifications, { key });
  notifications.splice(index, 1);
  render();
};

function render() {
  instances.forEach((i) => i.forceUpdate());
}

export { toast };

export default class ToastNotifications extends PureComponent {
  static displayName = 'ToastNotifications';

  componentDidMount() {
    instances.push(this);
  }

  componentWillUnmount() {
    const index = instances.indexOf(this);
    instances.splice(index, 1);
  }

  onConfirmButtonClick(item, index, event) {
    event && event.preventDefault();
    remove(item.key || index);
    item.onConfirm && item.onConfirm();
  }

  onCloseButton(item, index, event) {
    event.preventDefault();
    remove(item.key || index);
    item.onClose && item.onClose();
  }

  getConfirmContent(item, index) {
    const liveRegion = new OnDemandLiveRegion();
    liveRegion.say(item.message);
    const classes = cn(
      'toast-inner cf',
      { 'toast-inner--icon': item.icon },
      item.confirmType,
    );
    const confirmButtonText = item.confirmButtonText || 'Confirm';
    return (
      <div className={classes} key={index}>
        <Icon
          name="filled_circle_exclamation"
          className="alert-icon"
          size="2em"
        />
        <div className="toast-title">{item.title}</div>
        <div
          className="toast-msg before"
          aria-label={item.message}
          data-icon={item.icon}
        >
          {item.message}
        </div>
        <a
          className="dismiss"
          onClick={(e) => this.onCloseButton(item, index, e)}
        >
          ×
        </a>
        <Button
          isDanger={item.confirmType === 'confirm-error'}
          onClick={() => this.onConfirmButtonClick(item, index)}
        >
          {confirmButtonText}
        </Button>
      </div>
    );
  }

  getCtaContent(item, index) {
    const liveRegion = new OnDemandLiveRegion();
    liveRegion.say(item.message);
    const classes = cn('toast-inner cf', 'cta');
    const confirmButtonText = item.confirmButtonText || 'Confirm';
    return (
      <div className={classes} key={index}>
        <div className="toast-title">{item.title}</div>
        <div
          className="toast-msg before"
          aria-label={item.message}
          dangerouslySetInnerHTML={{ __html: item.message }}
        />
        <a
          className="dismiss"
          onClick={(e) => this.onCloseButton(item, index, e)}
        >
          ×
        </a>
        <div className="action-buttons">
          <a onClick={(e) => this.onConfirmButtonClick(item, index, e)}>
            {confirmButtonText}
          </a>
          <a onClick={(e) => this.onCloseButton(item, index, e)}>Cancel</a>
        </div>
      </div>
    );
  }

  getDefaultContent(item, index) {
    const liveRegion = new OnDemandLiveRegion({
      level: 'assertive',
      delay: 3000,
    });
    liveRegion.say(item.message);
    const classes = cn(
      'toast-inner cf',
      { 'toast-inner--icon': item.icon },
      item.type || 'error',
    );
    return (
      <div className={classes} key={index}>
        {item.icon && (
          <Icon name={item.icon} className="custom_icon" size="1em" />
        )}
        <div
          className="toast-msg before"
          aria-label={item.message}
          data-icon={item.icon || 'W'}
        >
          {item.message}
        </div>
        <a className="dismiss" onClick={() => remove(item.key || index)}>
          ×
        </a>
      </div>
    );
  }

  render() {
    const classes = cn('toast-wrapper', { active: notifications.length > 0 });
    return (
      <div className={classes}>
        {notifications.map(function (item, index) {
          switch (item.type) {
            case 'confirm':
              return this.getConfirmContent(item, index);
            case 'cta':
              return this.getCtaContent(item, index);
            default:
              return this.getDefaultContent(item, index);
          }
        }, this)}
      </div>
    );
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Icon } from 'legacy-packages/ft-cb';
import Tabs from './components/Tabs';

const Wrapper = styled.div`
  display: flex;
  height: 40px;
  box-shadow: ${(props) =>
    props.theme === 'Default' ? 'inset 0 -1px 0 0 #ececec' : 'none'};
  background-color: #fff;
`;

const navBlock = `
  display: inline-block;
  width: 26px;
  height: 40px;
  box-shadow: inset 0 -1px 0 0 #ececec;
  background-color: #FFF;
  text-align: center;

  &:hover use {
    fill: #0077c8;
  }

  &.nav-is-enabled {
    visibility: visible;
  }

  &.nav-is-disabled {
    visibility: hidden;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  box-shadow: inset 0 -1px 0 0 #ececec;
  overflow: hidden;
`;

const NavPrev = styled.button`
  ${navBlock}
  svg {
    width: 26px;
    margin: 10px 0;
  }
`;

const NavNext = styled.button`
  ${navBlock}
  svg {
    margin: 10px 0;
    transform: rotate(180deg);
  }
`;

/**
 * Draws responsive tabs
 * */
class ResponsiveTabs extends PureComponent {
  static displayName = 'ResponsiveTabs';

  static propTypes = {
    ariaLabelNextTab: PropTypes.string,
    ariaLabelPrevTab: PropTypes.string,
    items: PropTypes.array.isRequired,
    tabWidth: PropTypes.number,
    activeTabIndex: PropTypes.number.isRequired,
    doResize: PropTypes.func,
    theme: PropTypes.string.isRequired,
    setActiveTab: PropTypes.func,
    renderTabButton: PropTypes.func.isRequired,
    // Align left most possible the selected tab when property is true.
    leftAlign: PropTypes.bool,
    // Use different tabbing through items (e.g. videos carousel).
    isCarousel: PropTypes.bool,
  };

  static defaultProps = {
    ariaLabelPrevTab: null,
    ariaLabelNextTab: null,
    doResize: () => {},
    setActiveTab: () => {},
    tabWidth: 148,
    theme: 'Default',
    leftAlign: false,
    isCarousel: false,
  };

  constructor(props) {
    super(props);

    this.animatedContainerRef = React.createRef();

    this.onPrevNavClick = this.onPrevNavClick.bind(this);
    this.onNextNavClick = this.onNextNavClick.bind(this);
    this.doResize = this.doResize.bind(this);
  }

  /**
   * Prev navigation icon click handler
   * */
  onPrevNavClick() {
    this.animatedContainerRef.current.doNavigate('left');
  }

  /**
   * Next navigation icon click handler
   * */
  onNextNavClick() {
    this.animatedContainerRef.current.doNavigate('right');
  }

  /**
   * Resize handler
   * */
  doResize() {
    this.animatedContainerRef.current.resizeHandler();
  }

  render() {
    const {
      ariaLabelPrevTab,
      ariaLabelNextTab,
      tabWidth,
      items,
      activeTabIndex,
      setActiveTab,
      renderTabButton,
      theme,
      leftAlign,
      isCarousel,
    } = this.props;

    const isNavButtonsFocusable = !!isCarousel;

    return (
      <Wrapper className="ResponsiveTabs">
        <NavPrev
          tabIndex={isNavButtonsFocusable ? 0 : -1}
          aria-label={ariaLabelPrevTab || 'tab previous arrow'}
          className="ResponsiveTabs--nav-prev cursor-pointer"
          onClick={this.onPrevNavClick}
        >
          <Icon name="nav-arrow" width="10" height="16" key="nav-arrow-left" />
        </NavPrev>
        <Container className="ResponsiveTabs--items-wrapper">
          <Tabs
            tabWidth={tabWidth}
            ref={this.animatedContainerRef}
            items={items}
            theme={theme}
            activeTabIndex={activeTabIndex}
            setActiveTab={setActiveTab}
            renderTabButton={renderTabButton}
            leftAlign={leftAlign}
            isCarousel={isCarousel}
          />
        </Container>
        <NavNext
          tabIndex={isNavButtonsFocusable ? 0 : -1}
          aria-label={ariaLabelNextTab || 'tab next arrow'}
          className="ResponsiveTabs--nav-next cursor-pointer"
          onClick={this.onNextNavClick}
        >
          <Icon name="nav-arrow" width="10" height="16" key="nav-arrow-right" />
        </NavNext>
      </Wrapper>
    );
  }
}

export default ResponsiveTabs;

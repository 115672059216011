const LOGOUT_WHITELIST = [
  'persist:ui-fym',
  'persist:visited-subunits',
  'persist:visited-units',
  'qb-table-columns',
  'index-qb-table-columns',
  'qb-table-columns-v2',
  'index-qb-table-columns-v2',
  'dismissed-todo-cards',
  'bookmarked-questions',
  'jstor-ts',
];

export default LOGOUT_WHITELIST;

let collected = [];
const LOG_TYPES = ['log', 'info', 'warn', 'error'];

/**
 * log helper
 *
 * Wrapper around console.log (or, warning, or error, etc) to collect what is logged and ship it to us.
 * If the first value is in LOG_TYPES, we call console.warn for example. Otherwise log is used.
 * This is to be used in the exact same way as console.log(...)
 */
function log(...args) {
  const message = log.collect(...args);
  window.console[message.type](...message.log);
}

LOG_TYPES.forEach((name) => {
  log[name] = (...args) => log(name, ...args);
});

log.getCollected = function () {
  return collected.slice(0);
};

log.collect = function (...args) {
  const message = {
    type: LOG_TYPES.includes(args[0]) ? args.splice(0, 1) : 'log',
    timestamp: new Date(),
    log: args,
  };

  collected.push(message);

  if (collected.length > 10) {
    collected.shift();
  }

  return message;
};

export default log;

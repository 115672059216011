import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../components/Icon';
import './index.scss';

/**
 * Draws a Switch component
 * */
class Switch extends PureComponent {
  static displayName = 'Switch';

  static defaultProps = {
    value: 'on',
    disabled: false,
    states: {
      on: {
        label: 'On',
        icon: 'switch-on',
        labelClassName: '',
        iconClassName: '',
      },
      off: {
        label: 'Off',
        icon: 'switch-off',
        labelClassName: '',
        iconClassName: '',
      },
    },
  };

  static propTypes = {
    /*
     * Sets if it's disabled or not
     * */
    disabled: PropTypes.bool,
    /**
     * Main container class name
     * */
    className: PropTypes.string,
    /**
     * Toggle value, sets switch to active/inactive state
     * */
    value: PropTypes.oneOf(['on', 'off']).isRequired,
    /**
     * Component configuration states based per value
     * */
    states: PropTypes.shape({
      on: PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.string,
        labelClassName: PropTypes.string,
        iconClassName: PropTypes.string,
      }),
      off: PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.string,
        labelClassName: PropTypes.string,
        iconClassName: PropTypes.string,
      }),
    }),
    /**
     * Toggle callback func
     * */
    toggleCallback: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      toggle: props.value === 'on',
    };

    this.onToggleClick = this.onToggleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  /**
   * Toggle handler click event
   * */
  onToggleClick() {
    this.setState((prevState, props) => {
      const { toggleCallback } = props;
      const newToggle = !prevState.toggle;

      /**
       * Notify callback when toggle changes
       * */
      if (toggleCallback) {
        toggleCallback(newToggle);
      }

      return {
        toggle: newToggle,
      };
    });
  }

  handleKeyDown(e) {
    if (e.keyCode === 32 || e.keyCode === 13) {
      // spacebar or enter
      e.preventDefault();
      this.onToggleClick();
    }
  }

  componentWillReceiveProps({ value }) {
    this.setState({
      toggle: value === 'on',
    });
  }

  render() {
    const { className = '', states, disabled } = this.props;
    const { toggle } = this.state;

    const containerClassName = `ft-switch ${className}`;
    const activeState = states[toggle ? 'on' : 'off'];

    const label = activeState.label;
    const iconName = activeState.icon;

    const labelClassName = `${activeState.labelClassName}`;
    const iconClassName = `${activeState.iconClassName}`;

    return (
      <div className={containerClassName}>
        {label && <span className={labelClassName}>{label}</span>}
        <a
          tabIndex={0}
          role="switch"
          aria-checked={toggle}
          disabled={disabled}
          onClick={this.onToggleClick}
          onKeyDown={this.handleKeyDown}
          className={`switch-icon ${iconClassName}`}
        >
          <Icon name={iconName} />
        </a>
      </div>
    );
  }
}

export default Switch;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import './styles.scss';

/**
 * Custom wrapper for 'react-custom-scrollbars' package.
 * It modifies the position and background-color of the trackers.
 * Any default properties can be found at:
 * https://github.com/malte-wessel/react-custom-scrollbars/blob/master/docs/API.md
 * */
const ScrollableContainer = (props) => {
  const {
    children,
    className,
    renderTrackHorizontal,
    renderTrackVertical,
    ...otherProps
  } = props;
  const horizontalTrack =
    renderTrackHorizontal ||
    ((trackProps) => <div {...trackProps} className="horizontal-track" />);
  const verticalTrack =
    renderTrackVertical ||
    ((trackProps) => <div {...trackProps} className="vertical-track" />);

  return (
    <Scrollbars
      className={cn('ScrollableContainer', className)}
      renderTrackHorizontal={horizontalTrack}
      renderTrackVertical={verticalTrack}
      {...otherProps}
    >
      {children}
    </Scrollbars>
  );
};

ScrollableContainer.displayName = 'ScrollableContainer';

ScrollableContainer.propTypes = {
  // The classes from parent for styling the component.
  className: PropTypes.string,
  // The custom horizontal track.
  renderTrackHorizontal: PropTypes.func,
  // The custom vertical track.
  renderTrackVertical: PropTypes.func,
};

ScrollableContainer.defaultProps = {
  className: null,
  renderTrackHorizontal: null,
  renderTrackVertical: null,
};

export default ScrollableContainer;

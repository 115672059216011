const ELEMENT_CATEGORIES = {
  NAKED: 'naked',
  OUTLINE: 'outline',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  DANGER: 'danger',
  GOLDEN: 'golden',
  ALERT: 'alert',
};

const STATES = {
  DEFAULT: 'default',
  HOVER: 'hover',
  ACTIVE: 'active',
  DISABLED: 'disabled',
};

export { ELEMENT_CATEGORIES, STATES };

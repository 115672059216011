module.exports = {
  content: [
    'Roboto',
    'HelveticaNeue',
    'Helvetica Neue',
    'Helvetica',
    'Arial',
    'sans-serif',
  ],
  heading: [
    'Roboto Slab',
    'HelveticaNeue',
    'Helvetica Neue',
    'Helvetica',
    'Arial',
    'sans-serif',
  ],
};

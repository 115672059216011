/**
 * Available/Supported Statuses for an assignment
 * */
export const ASSIGNMENT_STATUSES = {
  'view-progress': {
    icon: 'status-inprogress',
  },
  'take-quiz-begin': {
    icon: 'unlocked',
    cellClassName: 'take-quiz-begin',
  },
  'take-quiz-continue': {
    label: 'In Progress',
    cellClassName: 'take-quiz-continue',
  },
  'awaiting-results': {
    label: 'Awaiting Results',
    cellClassName: 'awaiting-results',
  },
  'refresh-for-results': {
    label: 'Awaiting Results',
    cellClassName: 'awaiting-results',
  },
  locked: {
    icon: 'locked',
  },
  unlock: {
    icon: 'locked',
  },
};

/**
 * Used for determine if the status of an assignment
 * can be considered as fallow:
 * - < 80 - assignment in progress
 * - >= 80 - assignment in results
 * */
export const ASSIGNMENT_STATUS_CRITERIA = 80;

export const largeFontSubjects = [
  87, // AP Chinese Language and Culture
  88, // AP Japanese Language and Culture
];

export const TEI_TYPES = [
  'clozeassociation', // Drag and Drop
  'clozetext', // Fill in the Blanks
  'hotspot', // Hotspots
  'tokenhighlight', // Token Highlight
  'clozedropdown', // Dropdowns
];

export const QUESTION_TYPES = {
  multipleChoice: 'questions/multiple_choice',
  freeResponse: 'questions/free_response',
  mcq: 'questions/multiple_choice',
  frq: 'questions/free_response',
};

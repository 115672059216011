module.exports = {
  16.4: '4.4rem',
  30: '7.5rem',
  40: '10rem',
  74: '18.5rem',
  80: '20rem',
  160: '40rem',
  200: '50rem',
  260: '65rem',
  320: '80rem',
  '50-screen': '50%',
};

module.exports = {
  0.2: '0.2rem',
  0.4: '0.4rem',
  0.5: '0.5rem',
  0.6: '0.6rem',
  0.8: '0.8rem',
  0.9: '0.9rem',
  4.6: '1.6rem',
  4.2: '1.2rem',
  4.4: '1.4rem',
  4.8: '1.8rem',
  2.7: '0.7rem',
  2.8: '0.8rem',
  4.3: '1.3rem',
  8.1: '2.1rem',
  8.2: '2.2rem',
  8.4: '2.4rem',
  8.5: '2.5rem',
  8.8: '2.8rem',
  12.2: '3.2rem',
  12.6: '3.6rem',
  16.6: '4.6rem',
  16.8: '4.8rem',
  28.2: '7.2rem',
  98.2: '25rem',
};

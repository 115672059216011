import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from '@emotion/styled';
import { forEach, debounce } from 'lodash';

const ItemStyle = styled.button`
  box-shadow: inset 0 -1px 0 0 #ececec;
  width: 148px;
  height: 40px;

  & > a,
  & > span {
    display: block;
    color: #0077c8;
    font-size: 16px;
    font-weight: ${(props) => (props.theme === 'Default' ? 700 : 'normal')};
    padding: 9px;
    text-align: center;
    line-height: 1.38;
    text-decoration: none;
  }

  &.is-selected {
    box-shadow: ${(props) =>
      props.theme === 'Default'
        ? 'inset 0 1px 0 0 #eaeaea, inset 1px 0 0 0 #eaeaea, inset -1px 0 0 0 #eaeaea'
        : 'inset 0 0 0 0'};
    a,
    span {
      /*border: ${(props) =>
        props.theme === 'Default'
          ? 'inherit'
          : 'border: solid 1px #c9c9c9;'}; */
      color: #1e1e1e;
    }
  }
`;

const ItemsStyle = styled.div`
  position: absolute;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  height: ${(props) => (props.theme === 'Default' ? '40px' : '')};
  transition-duration: 1s;
`;

class Tabs extends PureComponent {
  static displayName = 'Tabs';

  static propTypes = {
    tabWidth: PropTypes.number.isRequired,
    items: PropTypes.array.isRequired,
    activeTabIndex: PropTypes.number.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    renderTabButton: PropTypes.func.isRequired,
    theme: PropTypes.string.isRequired,
    // Align left most possible the selected tab when property is true.
    leftAlign: PropTypes.bool,
    // Use different tabbing through items (e.g. videos carousel).
    isCarousel: PropTypes.bool,
  };

  static defaultProps = {
    leftAlign: false,
    isCarousel: false,
  };

  constructor(props) {
    super(props);

    const { activeTabIndex, tabWidth, items } = props;

    this.itemsRef = React.createRef();
    this.hasNavigation = false;

    /**
     * Defined as a default value
     * */
    this.state = {
      activeTabIndex,
    };

    /**
     * Tab width
     * */
    this.tabWidth = tabWidth;
    this.totalTabs = items.length;

    this.onTabClick = this.onTabClick.bind(this);
    this.doNavigate = this.doNavigate.bind(this);
    this.getTabsPerPage = this.getTabsPerPage.bind(this);
    this.setTabNavVisibility = this.setTabNavVisibility.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);
    this.onTransitionEnd = this.onTransitionEnd.bind(this);
    this.getTabsTransform = this.getTabsTransform.bind(this);
    this.setContainerSize = this.setContainerSize.bind(this);
    this.getNavWidth = this.getNavWidth.bind(this);
    this.overrideOptions = this.overrideOptions.bind(this);
    this.extractWidthFromStyle = this.extractWidthFromStyle.bind(this);
    this.getStaticContainerWidth = this.getStaticContainerWidth.bind(this);
    this.realignNavigation = this.realignNavigation.bind(this);
    this.addActiveTabOnViewPort = this.addActiveTabOnViewPort.bind(this);
    this.setActiveTabIndex = this.setActiveTabIndex.bind(this);
    this.handleFocusedTab = this.handleFocusedTab.bind(this);
    this.onTabsContainerFocus = this.onTabsContainerFocus.bind(this);
  }

  componentDidMount() {
    this.movableContainer = this.itemsRef.current;
    this.staticContainer = this.movableContainer.parentElement;
    this.mainContainer = this.movableContainer.closest('.ResponsiveTabs');
    this.tabItems = this.movableContainer.querySelectorAll(
      '.ResponsiveTabs--item',
    );
    window.addEventListener('resize', debounce(this.resizeHandler, 200));
    this.movableContainer.addEventListener(
      'transitionend',
      this.onTransitionEnd,
    );

    this.navPrevElement = this.mainContainer.querySelector(
      '.ResponsiveTabs--nav-prev',
    );
    this.navNextElement = this.mainContainer.querySelector(
      '.ResponsiveTabs--nav-next',
    );

    this.getTabsPerPage();
    this.setContainerSize();
    this.setTabNavVisibility();

    /**
     * Reset navigation if no navigation is available
     * */
    this.overrideOptions();

    const { activeTabIndex } = this.state;
    this.props.setActiveTab(activeTabIndex);
    this.addActiveTabOnViewPort(activeTabIndex);
  }

  componentDidUpdate(prevProps) {
    const { activeTabIndex } = this.props;
    this.setActiveTabIndex(prevProps, activeTabIndex);
  }

  setActiveTabIndex(prevProps, activeTabIndex) {
    if (prevProps.activeTabIndex !== activeTabIndex) {
      this.setState({
        activeTabIndex,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
    this.movableContainer.removeEventListener(
      'transitionend',
      this.onTransitionEnd,
    );
  }

  /**
   * Sets the navigation arrows visibility
   * */
  setTabNavVisibility() {
    /**
     * Hide nav arrows when all tabs are visible
     * */
    const disabledClassName = 'nav-is-disabled';
    const enabledClassName = 'nav-is-enabled';

    let prevClassname;
    let nextClassname;
    const currentPosition = this.getTabsTransform();
    if (this.totalTabs < this.tabsPerPage && currentPosition > 0) {
      prevClassname = disabledClassName;
      nextClassname = disabledClassName;
    } else {
      prevClassname = enabledClassName;
      nextClassname = enabledClassName;

      if (currentPosition >= 0) {
        prevClassname = disabledClassName;
      }

      const staticContainerWidth = this.getStaticContainerWidth();
      const moveableContainerWidth = this.movableContainer.offsetWidth;
      const availableSpace = -(currentPosition - staticContainerWidth);
      const hasRightAvailableTabs =
        availableSpace < moveableContainerWidth &&
        availableSpace >= this.tabWidth;
      if (!hasRightAvailableTabs) {
        nextClassname = disabledClassName;
      }
    }

    this.navPrevElement.classList.remove(enabledClassName);
    this.navPrevElement.classList.remove(disabledClassName);
    this.navNextElement.classList.remove(enabledClassName);
    this.navNextElement.classList.remove(disabledClassName);

    this.navPrevElement.classList.add(prevClassname);
    this.navNextElement.classList.add(nextClassname);

    if (this.totalTabs <= this.tabsPerPage) {
      this.mainContainer.classList.add('no-navigation');
      this.hasNavigation = false;
    } else {
      this.mainContainer.classList.remove('no-navigation');
      this.hasNavigation = true;
    }
  }

  /**
   * On tab click event handler
   * */
  onTabClick(event, index) {
    const tabIndex =
      index ||
      Number(
        event.currentTarget.closest('.ResponsiveTabs--item').dataset.index,
      );
    this.props.setActiveTab(tabIndex);

    this.setState({
      activeTabIndex: tabIndex,
    });
  }

  /**
   * Navigates to left or right based on the triggering prop
   * @param {string} direction The direction where to navigate
   * @param {number} xTimes Navigate by number of times
   * */
  doNavigate(direction, xTimes = 1) {
    const staticContainerWidth = this.getStaticContainerWidth();
    const tabsWidth = this.movableContainer.offsetWidth;
    const currentPosition = this.getTabsTransform();
    const rightAvailableWidth =
      tabsWidth + currentPosition - staticContainerWidth;
    const leftAvailableWidth = -currentPosition;

    /**
     * Move at least 1 tab
     * */
    const shouldMove = staticContainerWidth - this.tabWidth || this.tabWidth;

    let movePx;
    if (direction === 'right') {
      movePx =
        currentPosition -
        (rightAvailableWidth > shouldMove ? shouldMove : rightAvailableWidth);
    } else {
      movePx =
        currentPosition +
        (leftAvailableWidth > shouldMove ? shouldMove : leftAvailableWidth);
    }

    this.movableContainer.style.transform = `translateX(${movePx * xTimes}px)`;
  }

  /**
   * Get tabs transform
   * */
  getTabsTransform() {
    const transformMatch =
      this.movableContainer.style.transform.match(/\((.*)px\)/);
    const transformPx = (transformMatch && transformMatch[1]) || 0;

    return Number(transformPx);
  }

  /**
   * Triggered after tab container finished moving
   * @param {object} event The event object handler
   * */
  onTransitionEnd(event) {
    if (event.propertyName === 'transform') {
      this.setTabNavVisibility();
    }
  }

  /**
   * Calculates number of tabs available on page
   * */
  getTabsPerPage() {
    const navWidth = this.getNavWidth();
    const width = this.mainContainer.offsetWidth - 2 * navWidth;
    this.tabsPerPage = Math.floor(width / this.tabWidth) || 1;
  }

  /*
   * Resize handler - resize components
   * */
  resizeHandler() {
    this.getTabsPerPage();
    this.setContainerSize();
    this.setTabNavVisibility();
    /**
     * Reset navigation if no navigation is available
     * */
    this.overrideOptions();
    this.realignNavigation();
  }

  /**
   * Calculates and sets the container width
   * */
  setContainerSize() {
    this.staticContainer.style.width = `${this.tabsPerPage * this.tabWidth}px`;
  }

  /**
   * Get nav width
   * @return {number} Width of the element
   * */
  getNavWidth() {
    return this.navPrevElement.offsetWidth;
  }

  /**
   * Override options based on the space available
   * for better tab width reprezentation
   * */
  overrideOptions() {
    const oldTabWidth = this.props.tabWidth;
    const navWidth = this.getNavWidth();
    const tabsContainerWidth = this.mainContainer.offsetWidth - 2 * navWidth;
    const tabsPerPage = Math.floor(tabsContainerWidth / oldTabWidth);
    const newTabWidth = Math.floor(tabsContainerWidth / tabsPerPage);

    if (newTabWidth > oldTabWidth) {
      forEach(this.tabItems, (item) => (item.style.width = `${newTabWidth}px`));

      this.tabWidth = newTabWidth;
      this.staticContainer.style.width = `${tabsPerPage * newTabWidth}px`;
    }

    this.movableContainer.style.width = `${this.totalTabs * newTabWidth}px`;
  }

  /**
   * Extract width number from style
   * @param {string} styleProp
   * @return {number}
   * */
  extractWidthFromStyle(styleProp) {
    const match = styleProp.match(/[0-9.]+/) || [];
    return match[0] || 0;
  }

  /**
   * Get static container width
   * @return {number}
   * */
  getStaticContainerWidth() {
    return (
      this.extractWidthFromStyle(this.staticContainer.style.width) ||
      Number(this.staticContainer.offsetWidth)
    );
  }

  /**
   * Realign navigation to fix white spaces and imperfect moves due to resizing
   * */
  realignNavigation() {
    const hasMoved =
      this.movableContainer.style.transform !== 'translateX(0px)';
    if (hasMoved) {
      let moveBy;
      if (this.hasNavigation) {
        if (this.navNextElement.classList.contains('nav-is-disabled')) {
          //Fill right white space with tabs
          moveBy = -((this.totalTabs - this.tabsPerPage - 1) * this.tabWidth);
        } else {
          // Recalculate movable container pixels to be multiple of the new tab width
          const leftMovedTabs = -Math.round(
            this.getTabsTransform() / this.tabWidth,
          );
          moveBy = -(leftMovedTabs * this.tabWidth);
        }
      } else {
        moveBy = 0;
      }

      this.movableContainer.style.transform = `translateX(${moveBy}px)`;
    }
  }

  /**
   * Add the active tab  on viewport
   * @param {number} tabIndex The tab index where to navigate
   * */
  addActiveTabOnViewPort(tabIndex) {
    const { tabsPerPage, totalTabs, movableContainer, props } = this;
    const { leftAlign } = props;
    const tabsWidth = movableContainer.offsetWidth;
    const tabWidth = tabsWidth / totalTabs;

    if (leftAlign) {
      if (tabIndex > 1 && totalTabs > tabsPerPage) {
        let tabsToScroll = tabIndex - 1;

        if (totalTabs - tabIndex < tabsPerPage) {
          const availableTabsAfter = totalTabs - tabIndex;
          const availableTabsWithSelectedOne = availableTabsAfter + 1;
          tabsToScroll -= tabsPerPage - availableTabsWithSelectedOne;
        }

        const widthToScroll = tabsToScroll * tabWidth;
        setTimeout(() => {
          this.movableContainer.style.transform = `translateX(${-widthToScroll}px)`;
        }, 0);
      }
    } else if (tabIndex > tabsPerPage) {
      const navigatesNr = Math.floor(tabIndex / tabsPerPage);
      this.doNavigate('right', navigatesNr);
    }
  }

  /**
   * @param {number} indexToFocus - the tab item to leave focused
   */
  focusAndBlur = (indexToFocus) => {
    const FOCUS_CLASS = 'with-focus';

    // remove focus class from all items in anticipation of adding it to an element after this
    const oldFocusedTabBtnSelector = `button.ResponsiveTabs--item.${FOCUS_CLASS}`;
    const focusedElements = document.querySelectorAll(oldFocusedTabBtnSelector);
    Array.from(focusedElements).forEach((element) =>
      element.classList.remove(FOCUS_CLASS),
    );

    // we just want to remove focus/ styling
    if (indexToFocus === -1) {
      return true;
    }

    // then mark the next element to the right or left with the correct class
    const focusedTabBtnSelector = `button.ResponsiveTabs--item[data-index="${indexToFocus}"]`;
    document.querySelector(focusedTabBtnSelector).classList.add(FOCUS_CLASS);
  };

  /**
   * Keydown handler to allow use of arrows to navigate left and right
   */
  keyDownHandler = (tabIndex, event) => {
    const { isCarousel, items } = this.props;
    let activeIndex = tabIndex;

    // only handle pressing of left and right arrow keys
    if ([37, 39].includes(event.keyCode)) {
      // Make sure to stop event bubbling
      event.preventDefault();
      event.stopPropagation();

      /**
       * if we hit the left arrow key at the first element or the right arrow key at the
       * last element, don't attempt navigation
       */
      if (
        (event.keyCode === 37 && activeIndex <= 1) ||
        (event.keyCode === 39 && activeIndex >= items.length)
      ) {
        return;
      }
      this.onTabClick(_, event.keyCode === 37 ? --activeIndex : ++activeIndex);
      document
        .querySelector(
          `button.ResponsiveTabs--item[data-index="${activeIndex}"]`,
        )
        .focus();
    }

    let isTab = false;
    let isShiftTab = false;

    if (event.keyCode === 9) {
      isTab = true;
      if (event.shiftKey) {
        isShiftTab = true;
      }
    }

    if (isCarousel && (isTab || isShiftTab)) {
      /**
       * if we try go to the left at the first element or to the right at the
       * last element, don't attempt navigation, instead allow browser tabbing to take over
       */
      if (
        (isShiftTab && activeIndex <= 1) ||
        (isTab && !isShiftTab && activeIndex >= items.length)
      ) {
        this.focusAndBlur(-1);
        return true;
      }

      // at this point, we want to take over navigation so we make sure to stop event bubbling
      event.preventDefault();
      event.stopPropagation();

      const nextTabIndex = isShiftTab ? activeIndex - 1 : activeIndex + 1;
      this.focusAndBlur(nextTabIndex);
      document
        .querySelector(
          `button.ResponsiveTabs--item[data-index="${nextTabIndex}"]`,
        )
        .focus();
    }
  };

  onTabsContainerFocus(event) {
    const focusedTab = document.activeElement;
    const itemIndex = Number.parseInt(
      focusedTab.getAttribute('data-index'),
      10,
    );

    if (itemIndex === 1 || itemIndex === this.totalTabs) {
      event.preventDefault();
      event.stopPropagation();
      this.handleFocusedTab(itemIndex);
    }
  }

  /**
   *
   * @param {number} focusedTabIndex - the tab item we want focused
   * @param {number} oldFocusedTabIndex - always undefined, but chose not to touch it in case it was holding some functionality
   *                                  together
   */
  handleFocusedTab(focusedTabIndex, oldFocusedTabIndex) {
    if (
      !oldFocusedTabIndex &&
      focusedTabIndex === this.totalTabs &&
      this.totalTabs > this.tabsPerPage
    ) {
      const widthToScroll =
        (focusedTabIndex - this.tabsPerPage + 1) * this.tabWidth;
      this.movableContainer.style.transform = `translateX(${-widthToScroll}px)`;
    } else {
      const transformCssValue = this.movableContainer.style.transform;
      const regexIntMatch = transformCssValue.match(/\d+/);
      const translatePxValueStr = regexIntMatch ? regexIntMatch[0] : 0;
      const translatePx = Number.parseInt(translatePxValueStr, 10);
      const scrolledTabs = translatePx / this.tabWidth;

      if (focusedTabIndex > 0 && focusedTabIndex <= this.totalTabs) {
        if (focusedTabIndex <= scrolledTabs) {
          const widthToScroll = (focusedTabIndex - 1) * this.tabWidth;
          this.movableContainer.style.transform = `translateX(${-widthToScroll}px)`;
        }

        if (focusedTabIndex > scrolledTabs + this.tabsPerPage) {
          const widthToScroll = oldFocusedTabIndex
            ? focusedTabIndex < oldFocusedTabIndex
              ? translatePx - this.tabWidth
              : translatePx + this.tabWidth
            : translatePx + this.tabWidth;
          this.movableContainer.style.transform = `translateX(${-widthToScroll}px)`;
        }

        this.focusAndBlur(focusedTabIndex);
      }
    }
  }

  render() {
    const { items, renderTabButton, isCarousel } = this.props;
    const { activeTabIndex } = this.state;

    return (
      <ItemsStyle
        className="ResponsiveTabs--items"
        ref={this.itemsRef}
        role="tablist"
        onFocus={isCarousel ? this.onTabsContainerFocus : null}
      >
        {items.map((item, index) => {
          const itemIndex = index + 1;
          const isSelected = itemIndex === activeTabIndex;
          const tabProps = isCarousel ? {} : { tabIndex: isSelected ? 0 : -1 };
          const className = cn('ResponsiveTabs--item', {
            'is-selected': isSelected,
          });

          return (
            <ItemStyle
              {...tabProps}
              data-index={itemIndex}
              key={`item-${index}`}
              className={className}
              onClick={this.onTabClick}
              role="tab"
              aria-selected={isSelected}
              onKeyDown={(event) => this.keyDownHandler(itemIndex, event)}
            >
              {isCarousel ? (
                <a role="button" data-role="" tabIndex={0}>
                  {renderTabButton(itemIndex)}
                </a>
              ) : (
                <span data-role="" className="tab-button--wrapper">
                  {renderTabButton(itemIndex)}
                </span>
              )}
            </ItemStyle>
          );
        })}
      </ItemsStyle>
    );
  }
}

export default Tabs;

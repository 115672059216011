import { isArray, isFunction } from 'lodash';

/**
 * Utility function to remove everything from localStorage if each key is not within a whitelist.
 * @param {?<Array<string>>} whitelist Optional override of keys to retain.
 * @param {?function} callback Optional callback function to invoke after clearing.
 */
export default function (whitelist, callback) {
  let keyWhitelist = isArray(whitelist) && whitelist;

  const persistAfterLogout =
    JSON.parse(window.localStorage.getItem('persistAfterLogout')) || [];
  keyWhitelist = keyWhitelist.concat(persistAfterLogout);

  // eslint-disable-next-line no-unused-vars
  for (const key in window.localStorage) {
    !keyWhitelist.some((whitelistedKey) => key.startsWith(whitelistedKey)) &&
      window.localStorage.removeItem(key);
  }

  isFunction(callback) && callback();
}

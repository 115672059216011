module.exports = {
  core: '62.5%',
  base: '1.4rem', // 14px

  22: [
    '2.2rem',
    {
      lineHeight: '2.2rem',
      letterSpacing: '-0.006px',
    },
  ],
  default: [
    '1.6rem',
    {
      lineHeight: '1.5rem',
    },
  ],
  sidebar: [
    '1.1rem',
    {
      lineHeight: '1.4rem',
    },
  ],
  'page-title': [
    '40px',
    {
      lineHeight: '35px',
      letterSpacing: '-0.012px',
    },
  ],
  'page-subtitle': [
    '16px',
    {
      lineHeight: '14px',
      letterSpacing: '0.436944px',
    },
  ],
  18: [
    '1.8rem',
    {
      lineHeight: '1.6rem',
    },
  ],
  14: [
    '1.4rem',
    {
      lineHeight: '1.5em',
    },
  ],
  10: '1rem',
  11: '1.1rem',
  12: '1.2rem',
  13: '1.3rem',
  15: '1.5rem',
  16: '1.6rem',
  19: '1.9rem',
  20: [
    '2rem',
    {
      lineHeight: '140%',
    },
  ],
  36: [
    '3.6rem',
    {
      lineHeight: 1.3,
    },
  ],
};

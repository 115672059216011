import React, { Fragment } from 'react';

export default {
  share: {
    viewBox: '0 0 24 24',
    width: 24,
    height: 24,
    body: () => (
      <path d="M3.4 20.4L20.85 12.92C21.66 12.57 21.66 11.43 20.85 11.08L3.4 3.6C2.74 3.31 2.01 3.8 2.01 4.51L2 9.12C2 9.62 2.37 10.05 2.87 10.11L17 12L2.87 13.88C2.37 13.95 2 14.38 2 14.88L2.01 19.49C2.01 20.2 2.74 20.69 3.4 20.4Z" />
    ),
  },
  shared: {
    viewBox: '0 0 24 24',
    width: 24,
    height: 24,
    body: () => (
      <Fragment>
        <g clipPath="url(#clip0_630_3)">
          <path d="M16.5 9C16.08 9 15.67 9.04 15.26 9.11L2.4 3.6C1.74 3.31 1.01 3.8 1.01 4.51L1 9.2C1 9.67 1.33 10.08 1.78 10.18L10 12L1.78 13.83C1.33 13.93 1 14.33 1 14.8L1.01 19.48C1.01 20.2 1.74 20.68 2.4 20.4L9.08 17.54C9.59 21.19 12.71 24 16.5 24C20.64 24 24 20.64 24 16.5C24 12.36 20.64 9 16.5 9ZM16.5 22C13.47 22 11 19.53 11 16.5C11 13.47 13.47 11 16.5 11C19.53 11 22 13.47 22 16.5C22 19.53 19.53 22 16.5 22Z" />
          <path d="M15.0135 19.3729C15.387 19.7476 15.9902 19.7476 16.3636 19.3729L20.4235 15.2896C20.7969 14.9149 20.7969 14.3096 20.4235 13.9349C20.0501 13.5602 19.4468 13.5602 19.0734 13.9349L15.6838 17.336L14.3337 15.9814C13.9603 15.6067 13.3571 15.6067 12.9836 15.9814C12.6102 16.3561 12.6102 16.9613 12.9836 17.336L15.0135 19.3729Z" />
        </g>
        <defs>
          <clipPath id="clip0_630_3">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </Fragment>
    ),
  },
};

import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import AppLoader from 'chameleon/ui-stack/utilities/app-loader';

import '../dayjs.config';

/**
 * The mount node precedes the script bundles. Ensure that div#root exists first.
 */
const rootElement = document.querySelector('#root');
window.addEventListener('load', () => {
  ReactDOM.render(<AppLoader />, rootElement);
});

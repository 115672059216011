import React from 'react';

export default {
  hide: {
    viewBox: '0 0 16 16',
    width: 16,
    height: 16,
    body: () => (
      <g fill="none">
        <path
          key="icon-hide-1"
          d="M9.10102 4.59888C7.80684 4.61557 6.53764 4.95747 5.41019 5.59311L5.8179 5.9436L6.11831 6.2011C7.04316 5.73333 8.06461 5.48838 9.10102 5.48582C12.7346 5.48582 15.4241 8.6402 15.4241 9.47707C15.4241 9.59089 15.4693 9.70005 15.5498 9.78054C15.6303 9.86102 15.7394 9.90624 15.8532 9.90624C15.9671 9.90624 16.0762 9.86102 16.1567 9.78054C16.2372 9.70005 16.2824 9.59089 16.2824 9.47707C16.2824 7.88916 12.9135 4.59888 9.10102 4.59888Z"
          fill="black"
        />
        <path
          key="icon-hide-2"
          d="M8.02098 7.40286C8.35741 7.2216 8.73322 7.12581 9.11536 7.1239C9.27131 7.12433 9.4269 7.13869 9.58029 7.16682C10.11 7.27406 10.587 7.55927 10.9322 7.97508C11.2634 8.37893 11.4475 8.88337 11.4543 9.40564C11.4578 9.77847 11.3642 10.1458 11.1825 10.4714L11.8477 11.0365C12.1532 10.5475 12.3143 9.98218 12.3127 9.40564C12.3114 8.67171 12.0554 7.96098 11.5883 7.39481C11.1213 6.82864 10.4723 6.44215 9.75196 6.30133C9.02424 6.14948 8.26641 6.25304 7.60612 6.59459C7.41139 6.70121 7.227 6.82573 7.05536 6.96654L7.72772 7.53876C7.82133 7.485 7.91946 7.43953 8.02098 7.40286Z"
          fill="black"
        />
        <path
          key="icon-hide-3"
          d="M15.1809 14.7487L11.4829 11.5943L10.7677 11.0364L7.25565 8.03937L6.60475 7.48145L5.49607 6.54444L4.78079 5.95791L2.05558 3.63326C1.98241 3.58464 1.89391 3.56465 1.80696 3.5771C1.72 3.58955 1.64066 3.63356 1.58408 3.70076C1.52749 3.76795 1.49762 3.85362 1.50015 3.94143C1.50268 4.02924 1.53744 4.11305 1.5978 4.17687L4.20141 6.40854C2.79231 7.45999 1.91252 8.75465 1.91252 9.47708C1.91252 9.5909 1.95774 9.70006 2.03822 9.78055C2.11871 9.86103 2.22787 9.90625 2.34169 9.90625C2.45551 9.90625 2.56467 9.86103 2.64516 9.78055C2.72564 9.70006 2.77086 9.5909 2.77086 9.47708C2.77086 9.03361 3.61488 7.91062 4.88093 6.9593L6.2185 8.09659C6.20224 8.13379 6.18311 8.16966 6.16127 8.20388C5.87199 8.87723 5.82733 9.63054 6.03504 10.3333C6.24274 11.0361 6.68973 11.6442 7.29856 12.0521C7.83381 12.4175 8.46728 12.612 9.11537 12.61H9.37287C9.92313 12.5666 10.4529 12.3818 10.9107 12.0735L14.716 15.2994C14.7795 15.3546 14.8608 15.3851 14.9449 15.3853C14.998 15.3859 15.0506 15.3747 15.0989 15.3524C15.1472 15.3301 15.1898 15.2974 15.2238 15.2565C15.2547 15.2205 15.2781 15.1787 15.2927 15.1336C15.3073 15.0885 15.3127 15.0409 15.3087 14.9936C15.3047 14.9464 15.2914 14.9004 15.2694 14.8583C15.2475 14.8163 15.2174 14.779 15.1809 14.7487ZM9.33711 11.7445C8.79827 11.7906 8.26007 11.6491 7.81357 11.344C7.35544 11.0507 7.0159 10.6047 6.85509 10.0851C6.71636 9.61538 6.73393 9.11334 6.90516 8.65451L10.2097 11.4512C9.94241 11.6086 9.64521 11.7085 9.33711 11.7445Z"
          fill="black"
        />
      </g>
    ),
  },
  show: {
    viewBox: '0 0 16 16',
    width: 16,
    height: 16,
    fill: 'none',
    body: () => (
      <g fill="none">
        <path
          key="icon-show-1"
          d="M16.4167 9.87966C16.4167 8.63926 13.2469 5.25 9.33333 5.25C5.41979 5.25 2.25 8.74408 2.25 9.90877"
          stroke="#414141"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          key="icon-show-2"
          d="M12.2551 9.87952C12.2572 10.4531 12.0822 11.0137 11.7534 11.4868C11.6416 11.6542 11.5109 11.8086 11.3638 11.9469C10.8805 12.406 10.2513 12.6864 9.58262 12.7404C8.91392 12.7945 8.24684 12.6189 7.69429 12.2434C7.14174 11.8679 6.73768 11.3156 6.55051 10.68C6.36333 10.0443 6.40454 9.3644 6.66715 8.75529C6.92977 8.14618 7.39765 7.64531 7.99161 7.33746C8.58557 7.02961 9.26911 6.9337 9.92652 7.06596C10.5839 7.19822 11.1748 7.55053 11.5992 8.06325C12.0235 8.57597 12.2552 9.2176 12.2551 9.87952Z"
          stroke="#414141"
          strokeWidth="1.2"
          strokeMiterlimit="10"
        />
      </g>
    ),
  },
  reset: {
    viewBox: '0 0 24 24',
    width: 24,
    heigth: 24,
    body: () => (
      <path d="M6 13C6 11.35 6.67 9.85 7.76 8.76L6.34 7.34C4.9 8.79 4 10.79 4 13C4 17.08 7.05 20.44 11 20.93V18.91C8.17 18.43 6 15.97 6 13ZM20 13C20 8.58 16.42 5 12 5C11.94 5 11.88 5.01 11.82 5.01L12.91 3.92L11.5 2.5L8 6L11.5 9.5L12.91 8.09L11.83 7.01C11.89 7.01 11.95 7 12 7C15.31 7 18 9.69 18 13C18 15.97 15.83 18.43 13 18.91V20.93C16.95 20.44 20 17.08 20 13Z" />
    ),
  },
  print: {
    viewBox: '0 0 24 24',
    width: 17,
    heigth: 16,
    body: () => (
      <path
        key="print-icon"
        d="M14.245 4.73694H3.04502C1.71702 4.73694 0.64502 5.80894 0.64502 7.13694V10.3369C0.64502 11.2169 1.36502 11.9369 2.24502 11.9369H3.84502V13.5369C3.84502 14.4169 4.56502 15.1369 5.44502 15.1369H11.845C12.725 15.1369 13.445 14.4169 13.445 13.5369V11.9369H15.045C15.925 11.9369 16.645 11.2169 16.645 10.3369V7.13694C16.645 5.80894 15.573 4.73694 14.245 4.73694ZM11.045 13.5369H6.24502C5.80502 13.5369 5.44502 13.1769 5.44502 12.7369V9.53694H11.845V12.7369C11.845 13.1769 11.485 13.5369 11.045 13.5369ZM14.245 7.93694C13.805 7.93694 13.445 7.57694 13.445 7.13694C13.445 6.69694 13.805 6.33694 14.245 6.33694C14.685 6.33694 15.045 6.69694 15.045 7.13694C15.045 7.57694 14.685 7.93694 14.245 7.93694ZM12.645 0.736938H4.64502C4.20502 0.736938 3.84502 1.09694 3.84502 1.53694V3.13694C3.84502 3.57694 4.20502 3.93694 4.64502 3.93694H12.645C13.085 3.93694 13.445 3.57694 13.445 3.13694V1.53694C13.445 1.09694 13.085 0.736938 12.645 0.736938Z"
        fill="#1E1E1E"
      />
    ),
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'chameleon/ui-stack/ui/base/button';

import './styles.scss';

const NoButton = (
  hideClose,
  onClick,
  btnText,
  btnProps,
  btnClassname,
  invertedOrder,
  useRedGreenButtons,
) =>
  !hideClose && (
    <Button
      isSecondary={!useRedGreenButtons}
      isDanger={useRedGreenButtons}
      onClick={onClick}
      dataTestId="ft-modal-no-button"
    >
      {btnText || 'No'}
    </Button>
  );

const YesButton = (
  onClick,
  canSubmit,
  btnText,
  btnProps,
  btnClassname,
  invertedOrder,
  useRedGreenButtons,
) => (
  <Button
    isPrimary={!useRedGreenButtons}
    isSuccess={useRedGreenButtons}
    onClick={onClick}
    disabled={!canSubmit}
    dataTestId="ft-modal-yes-button"
  >
    {btnText || 'Yes'}
  </Button>
);

const AdditionalButton = (onClick, canSubmit, btnText) => (
  <Button isSecondary onClick={onClick} disabled={!canSubmit}>
    {btnText}
  </Button>
);

/**
 * Renders YES/NO buttons for modals.
 * */
const ConfirmationButtons = ({
  hideClose,
  className,
  onClose,
  onSubmit,
  closeBtnText,
  submitBtnText,
  canSubmit,
  invertedOrder,
  submitButtonProps,
  closeButtonProps,
  submitButtonClassname,
  closeButtonClassname,
  onAdditionalButtonClick,
  additionalBtnText,
  additionalBtnClassname,
  additionalButtonProps,
  isAdditionalButtonEnabled,
  useRedGreenButtons,
}) => {
  const yesButton = YesButton(
    onSubmit,
    canSubmit,
    submitBtnText,
    submitButtonProps,
    submitButtonClassname,
    invertedOrder,
    useRedGreenButtons,
  );
  const noButton = NoButton(
    hideClose,
    onClose,
    closeBtnText,
    closeButtonProps,
    closeButtonClassname,
    invertedOrder,
    useRedGreenButtons,
  );

  const additionalButtonEnabled =
    isAdditionalButtonEnabled === null ? canSubmit : isAdditionalButtonEnabled;

  // AdditionalButton was added for the case where we need a "middle"
  // button between Yes/No, with a secondary action
  const additionalButton =
    additionalBtnText &&
    onAdditionalButtonClick &&
    AdditionalButton(
      onAdditionalButtonClick,
      additionalButtonEnabled,
      additionalBtnText,
      additionalButtonProps,
      additionalBtnClassname,
    );

  return (
    <div className={cn('ConfirmationButtons gap-x-12', className)}>
      {invertedOrder
        ? [yesButton, additionalButton, noButton]
        : [noButton, additionalButton, yesButton]}
    </div>
  );
};

ConfirmationButtons.displayName = 'ConfirmationButtons';

ConfirmationButtons.propTypes = {
  // The class for the styling the wrapper.
  className: PropTypes.string,
  // The handle for closing the modal (NO button).
  onClose: PropTypes.func.isRequired,
  // The handler for submitting the action (YES button).
  onSubmit: PropTypes.func.isRequired,
  // The text to display on close button.
  closeBtnText: PropTypes.string,
  // The text to display on submit button.
  submitBtnText: PropTypes.string,
  // Handler for enable/disable the submit button.
  canSubmit: PropTypes.bool,
  // Hides the NO Button for modals that just have one button
  hideClose: PropTypes.bool,
  //If there's need for the buttons to be in a reverse order.
  invertedOrder: PropTypes.bool,
  // Submit button properties: color and size.
  submitButtonProps: PropTypes.string,
  // Provides a more refined customization over the button color. background, etc
  submitButtonClassname: PropTypes.string,
  // Submit button properties: color and size.
  closeButtonProps: PropTypes.string,
  // Provides a more refined customization over the button color. background, etc
  closeButtonClassname: PropTypes.string,
  // The handler for the extra button click (Extra Button).
  onAdditionalButtonClick: PropTypes.func,
  additionalBtnText: PropTypes.string,
  additionalBtnClassname: PropTypes.string,
  additionalButtonProps: PropTypes.string,
  useRedGreenButtons: PropTypes.bool,
};

ConfirmationButtons.defaultProps = {
  className: null,
  closeBtnText: null,
  submitBtnText: null,
  canSubmit: true,
  hideClose: false,
  invertedOrder: false,
  submitButtonProps: '',
  submitButtonClassname: '',
  closeButtonProps: '',
  closeButtonClassname: '',
  onAdditionalButtonClick: null,
  additionalBtnText: '',
  additionalBtnClassname: '',
  additionalButtonProps: '',
  useRedGreenButtons: false,
};

export default ConfirmationButtons;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'chameleon/ui-stack/ui/base/button';
import Icon from 'chameleon/ui-stack/ui/base/icon';

const ButtonWithIcon = forwardRef((props, ref) => {
  const {
    icon,
    children,
    className,
    iconOrientation,
    iconClassName,
    iconWidth,
    iconHeight,
    containerClassName,
    iconAccessibility = {},
    labelProps = {},
    ...otherProps
  } = props;

  const isLeftOrientated = iconOrientation === 'left';
  const isRightOrientated = iconOrientation === 'right';
  const isCenterOriented = iconOrientation === 'center';

  let iconProps = {};
  if (iconWidth !== null) {
    iconProps.width = iconWidth;
  }
  if (iconHeight !== null) {
    iconProps.height = iconHeight;
  }

  const { disabled } = otherProps;

  return (
    <Button
      ref={ref}
      className={cn(className, 'flex items-center', {
        'justify-center': isCenterOriented,
        'flex-row-reverse': isRightOrientated,
      })}
      {...otherProps}
    >
      <Icon
        accessibility={iconAccessibility}
        id={icon}
        className={disabled ? 'text-black opacity-40' : iconClassName}
        {...iconProps}
      />
      {children && (
        <div
          {...labelProps}
          className={cn(
            {
              'text-center': isCenterOriented,
              'text-left': !isCenterOriented,
              'pl-4': isLeftOrientated,
              'pr-4': isRightOrientated,
            },
            containerClassName,
          )}
        >
          {children}
        </div>
      )}
    </Button>
  );
});

ButtonWithIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconOrientation: PropTypes.string,
  iconWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ButtonWithIcon.defaultProps = {
  className: '',
  iconOrientation: 'left',
  iconWidth: null,
  iconHeight: null,
};

export default ButtonWithIcon;

module.exports = {
  8.2: '2.2rem',
  8.4: '2.4rem',
  8.8: '2.8rem',
  9.8: '2.8rem',
  62: '15.8rem',
  30: '7.7rem',
  12.2: '3.2rem',
  12.6: '3.6rem',
  16.8: '4.8rem',
  20.8: '5.8rem',
  25: '6.4rem',
  84: '10rem',
  120: '30rem',
  '70-screen': '70vh',
  '85-screen': '85vh',
  '95-screen': '95vh',
  siderbar: 'calc(100% - 6.4rem)',
  screenmodal: 'calc(100% - 3rem)',
};

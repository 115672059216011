// N.B. endRoute does not work, the code can be buggy
export const buildUrl = (routes, endRoute, params, queries) => {
  let result = '';

  routes.forEach((route) => {
    let stop = false;

    // if you want to short circuit the build
    if (stop) {
      return;
    }

    if (route.path) {
      if (route.path === '/') {
        return;
      }

      if (endRoute && route.path.includes(endRoute)) {
        stop = true;
      }

      const _index = route.path.indexOf(':');
      if (_index > -1) {
        const paramIndex = route.path.match(/:(\w+)($|\/)/);
        const newRoute = route.path.replace(
          `:${paramIndex[1]}`,
          params[paramIndex[1]],
        );

        result += `/${newRoute}`;
      } else {
        result += `/${route.path}`;
      }
    }
  });
  if (queries && Object.keys(queries).length > 0) {
    result += stringifyQuery(queries);
  }
  return result;
};

export const addQuery = (query, newQuery) => ({ ...query, ...newQuery });

export const stringifyQuery = (queries) => {
  let queryStr = '?';
  Object.keys(queries).forEach((query, key) => {
    if (key > 0) {
      queryStr += '&';
    }
    queryStr += `${query}=${queries[query]}`;
  });

  return queryStr;
};

export const STATUS = {
  ALL_ASSIGNED: 'all-assigned',
  ALL_RESOURCES: 'all-resources',
  ASSIGNED: 'assigned',
  ASSIGNED_RESOURCES: 'assigned-resources',
  READY_TO_SCORE: 'ready-to-score',
  COMPLETED: 'completed',
  UNASSIGNED: 'unassigned',
};

export const PDF_STATUS = {
  ALWAYS_VISIBLE: 'always-visible',
  SHARED: 'shared',
  NOT_SHARED: 'not-shared',
};

export const ASSIGNMENT_STATUSES = [
  STATUS.ALL_ASSIGNED,
  STATUS.ASSIGNED,
  STATUS.READY_TO_SCORE,
  STATUS.COMPLETED,
];

export const ASSESSMENT_STATUSES = [
  STATUS.ALL_RESOURCES,
  STATUS.ASSIGNED_RESOURCES,
  STATUS.UNASSIGNED,
  PDF_STATUS.ALWAYS_VISIBLE,
  PDF_STATUS.SHARED,
  PDF_STATUS.NOT_SHARED,
];

export const ASSESSMENT_PDF_STATUSES = [
  PDF_STATUS.ALWAYS_VISIBLE,
  PDF_STATUS.SHARED,
  PDF_STATUS.NOT_SHARED,
];

export const ASSESSMENTS_DEFAULT_STATUS = STATUS.ALL_RESOURCES;
export const ASSIGNMENTS_DEFAULT_STATUS = STATUS.ALL_ASSIGNED;

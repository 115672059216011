import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { locationOrigin, originPort } from '../utility/Utils';

const MixedLink = (props) => {
  const { path, children, ...extraProps } = props;

  const getExternalHref = () => {
    if (path.includes('http://') || path.includes('https://')) {
      return path;
    }
    return `${locationOrigin}${path}`;
  };

  let to = path.replace(locationOrigin, '');
  if (originPort) {
    to = to.replace(`:${originPort}`, '');
  }
  const internalTarget = {
    internal: (
      <Link {...extraProps} to={to}>
        {children}
      </Link>
    ),
    external: (
      <a {...extraProps} href={getExternalHref()}>
        {children}
      </a>
    ),
  };

  const l = document.createElement('a');
  l.href = path;

  const urlOrigin = `${l.protocol}//${l.hostname}`;
  return internalTarget[urlOrigin === locationOrigin ? 'internal' : 'external'];
};
MixedLink.displayName = 'MixedLink';
MixedLink.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
};

export default MixedLink;

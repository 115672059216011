import * as types from 'legacy/src/constants/types';

export const ACTIONS = {
  SET_READ_ONLY: 'set-read-only',
  SET_STUDENT_ID: 'set-student-id',
  SET_SECTION_ID: 'set-section-id',
  SET_IMPERSONATOR_EDUCATION_PERIOD: 'SET-IMPERSONATOR-EDUCATION-PERIOD',
  SET_IMPERSONATOR_TEACHER_CLASSES: 'SET-IMPERSONATOR-TEACHER-CLASSES',
  SET_IMPERSONATOR_TEACHER_SUBJECTS: 'SET-IMPERSONATOR-TEACHER-SUBJECTS',
  SET_IMPERSONATOR_TEACHER_NAV: 'SET-IMPERSONATOR-TEACHER-NAV',
  SET_IMPERSONATOR_SUBJECT: 'SET-IMPERSONATOR-SUBJECT',
  SET_IMPERSONATOR_CURRENT_SUBJECT: 'SET-IMPERSONATOR-CURRENT-SUBJECT',
  SET_IS_WELCOME_VIDEO_FIRST_TIME: 'set-is-welcome-video-first-time',
};

export const LC_CONSTANTS = {
  RO: 'isReadOnly',
  AT: 'account_access_token',
  RT: 'account_refresh_token',
  EXP: 'expires',
  IAT: 'impersonator_access_token',
  IRT: 'impersonator_refresh_token',
  IEXP: 'impersonator_expires',
  STUDENT_ID: 'impersonating_student_id',
  SECTION_ID: 'impersonating_section_id',
  EDUCATION_PERIOD: 'impersonator_education_period',
  ISUBJECT: 'impersonator_subject',
  ICURRENTSUBJECT: 'impersonator_current_subject',
  IS_WELCOME_VIDEO_FIRST_TIME: 'impersonator_is_welcome_video_first_time',
  STUDENT_NAME: 'impersonating_student_name',
  SECTION_NAME: 'impersonating_section_name',
  IMPERSONATOR_REDIRECT_SUBJECT_ID: 'impersonator_redirect_subject_id',
};

export const SUBJECT_RESTRICTION_TYPES = {
  NOT_TEACHING: 'not-teaching',
  PROGRAM_AP: types.AP,
  PROGRAM_PREAP: types.PreAP,
};

export const IMPERSONATING_TELEMETRY_TYPES = {
  START: 'start',
  STOP: 'stop',
};

/* eslint-disable */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './index.scss';

const LoadingSpinner = (props) => {
  const height = props.height || 50;
  const { message, containerClassName = '', iconClassName = '' } = props;
  return (
    <div className={cn('LoadingSpinner__wrapper', containerClassName)}>
      <svg
        className={cn('LoadingSpinner', iconClassName)}
        version="1.1"
        baseProfile="tiny"
        style={{
          height,
          width: height,
        }}
        preserveAspectRatio="xMinYMin meet"
        viewBox="0 0 72 72"
        role="img"
      >
        <title>Loading Spinner</title>
        <path
          d={`M53.9,35.9h9c0,3.7-0.7,7.2-2.1,10.5s-3.3,6.1-5.8,8.5c-2.4,2.4-5.3,4.4-8.6,5.8s-6.7,2.1-10.4,2.1
           c-3.7,0-7.2-0.7-10.5-2.1s-6.1-3.3-8.5-5.8s-4.4-5.3-5.8-8.5s-2.1-6.7-2.1-10.5s0.7-7.2,2.1-10.5s3.3-6.1,5.8-8.5s5.3-4.4,8.6-5.8
           S32.3,9.1,36,9.1h5.3l-2.7-2.7L45,0.1l13.4,13.4L45,27.1l-6.4-6.4l2.6-2.6L36,18c-2.5,0-4.8,0.5-7,1.4s-4.1,2.2-5.7,3.8
           s-2.9,3.5-3.8,5.7c-0.9,2.2-1.4,4.5-1.4,7s0.5,4.8,1.4,7c0.9,2.2,2.2,4.1,3.8,5.7c1.6,1.6,3.5,2.9,5.7,3.8s4.5,1.4,7,1.4
           s4.8-0.5,7-1.4c2.2-0.9,4.1-2.2,5.7-3.8c1.6-1.6,2.9-3.5,3.8-5.7C53.4,40.7,53.9,38.4,53.9,35.9z`}
        />
      </svg>
      {message && <span className="LoadingSpinner__message">{message}</span>}
    </div>
  );
};

LoadingSpinner.propTypes = {
  height: PropTypes.number,
  message: PropTypes.string,
  containerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default LoadingSpinner;

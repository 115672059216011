import React, { Fragment } from 'react';

export default {
  save: {
    viewBox: '0 0 20 20',
    width: 16,
    height: 16,
    body: () => (
      <path
        className="fill-current"
        xmlns="http://www.w3.org/2000/svg"
        d="M14 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V4L14 0ZM16 16H2V2H13.17L16 4.83V16ZM9 9C7.34 9 6 10.34 6 12C6 13.66 7.34 15 9 15C10.66 15 12 13.66 12 12C12 10.34 10.66 9 9 9ZM3 3H12V7H3V3Z"
      />
    ),
  },
  lock: {
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
    body: () => (
      <path d="M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM9 6C9 4.34 10.34 3 12 3C13.66 3 15 4.34 15 6V8H9V6ZM17 20H7C6.45 20 6 19.55 6 19V11C6 10.45 6.45 10 7 10H17C17.55 10 18 10.45 18 11V19C18 19.55 17.55 20 17 20ZM12 17C13.1 17 14 16.1 14 15C14 13.9 13.1 13 12 13C10.9 13 10 13.9 10 15C10 16.1 10.9 17 12 17Z" />
    ),
  },
  unlock: {
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
    body: () => (
      <Fragment>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 8H17H15H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM17 20C17.55 20 18 19.55 18 19V11C18 10.45 17.55 10 17 10H7C6.45 10 6 10.45 6 11V19C6 19.55 6.45 20 7 20H17Z"
          fill="#323232"
        />
        <path
          d="M12 13C10.9 13 10 13.9 10 15C10 16.1 10.9 17 12 17C13.1 17 14 16.1 14 15C14 13.9 13.1 13 12 13Z"
          fill="#323232"
        />
        <path
          d="M19.0644 1C16.2688 1 14 2.99886 14 8H16.0258C16.0258 4.5 17.3931 2.99886 19.0644 2.99886C20.452 2.99886 21.6574 3.92833 22.0017 5.24757C22.1435 5.78726 22.7006 6.10708 23.2374 5.96716C23.7844 5.82724 24.1085 5.28755 23.9667 4.74786C23.3894 2.53912 21.3738 1 19.0644 1Z"
          fill="#323232"
        />
      </Fragment>
    ),
  },
  'more-actions': {
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
    body: () => (
      <path d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z" />
    ),
  },
  info_outline: {
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
    body: () => (
      <path d="M11 7H13V9H11V7ZM12 17C12.55 17 13 16.55 13 16V12C13 11.45 12.55 11 12 11C11.45 11 11 11.45 11 12V16C11 16.55 11.45 17 12 17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" />
    ),
  },
  info_outline_filled: {
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
    body: () => (
      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM13 9H11V7H13V9Z" />
    ),
  },
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Notifications from '../../utility/notify';

import TopBarContainer from './TopBarContainer';
import HelpMenu from './HelpMenu';
import UserMenu from './UserMenu';
import QuickTips from './QuickTips';

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { scrolled } = this.state;
    if (window.scrollY > 0 && !scrolled) {
      this.setState({
        scrolled: true,
      });
    } else if (window.scrollY <= 0 && scrolled) {
      this.setState({
        scrolled: false,
      });
    }
  };

  render() {
    const {
      logo,
      userData,
      contactOnly,
      router,
      subject,
      showPreapBranding,
      isQuickTipsVisible,
    } = this.props;
    const { scrolled } = this.state;
    const assignmentId = router && router.params && router.params.assignmentId;
    const isPreApSubject = subject && subject.isPreApSubject;
    const menuProps = { ...this.props };
    return (
      <TopBarContainer
        logo={logo}
        scrolled={scrolled}
        shouldCollapse={assignmentId}
        isPreApSubject={isPreApSubject}
        showPreapBranding={showPreapBranding}
      >
        <QuickTips isVisible={isQuickTipsVisible} />
        <HelpMenu
          contactOnly={contactOnly}
          user={userData.me}
          userData={userData}
          subjectData={subject}
          {...this.props}
        >
          {this.props.children}
        </HelpMenu>
        <UserMenu {...menuProps} />
        <Notifications />
      </TopBarContainer>
    );
  }
}

TopBar.displayName = 'TopBar';

TopBar.propTypes = {
  logo: PropTypes.object,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isAdmin: PropTypes.bool,
  isCoordinator: PropTypes.bool,
  isStaff: PropTypes.bool,
  isStudent: PropTypes.bool.isRequired,
  isTeacher: PropTypes.bool.isRequired,
  isDemo: PropTypes.bool,
};

export default TopBar;

import React from 'react';
import './index.scss';

/**
 * Pseudo tab component that styles it's children as tab content. Not connected to {@link Tabs} component.
 * */
const TabContent = ({ children }) => (
  <div>
    <div className="grey-background" />
    {children}
  </div>
);

export default TabContent;
